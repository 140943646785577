




import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class DmTouch extends Vue {
  @Prop() disabled?: boolean
  @Prop() tag?: string
  @Prop() href?: string
  @Prop({ default: 'active' }) activeClassName!: string
  @Prop({ default: false }) silent!: boolean // 是否需要静音
  onTouchstart ($event: TouchEvent) {
    if (this.disabled) { return }
    if (!$event.target) { return }
    let ele = $event.target as Element
    // 注：VUE的DOM重绘会把这个activeClassName去掉，所以会有无法添加activeClassName假象
    ele.classList.add(this.activeClassName)
    // if (!this.silent) { Lib.clickAudio.play() }
    this.$emit('touchstart', $event)
  }
  onTouchend ($event: TouchEvent) {
    if (this.disabled) { return }
    if (!$event.target) { return }
    let ele = $event.target as Element
    ele.classList.remove(this.activeClassName)
    this.$emit('touchend', $event)
  }
  onTap ($event: TouchEvent) {
    if (this.disabled) { return }
    this.$emit('tap', $event)
  }
  onClick ($event: MouseEvent) {
    if (this.disabled) { return }
    if (!Lib.supports.touch) { this.$emit('touchstart', $event) }
    // if (!Lib.supports.touch) { this.$emit('touchstart', $event) }
    this.$emit('click', $event)
  }
}
