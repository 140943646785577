







































































import { Component, Vue, Inject } from 'vue-property-decorator'
import Advert from '@/components/Advert/Advert.vue'
import Advert2 from '@/components/Advert/Advert2.vue'
import Advert3 from '@/components/Advert/Advert3.vue'
import Advert4 from '@/components/Advert/Advert4.vue'
import Advert5 from '@/components/Advert/Advert5.vue'
import Advert6 from '@/components/Advert/Advert6.vue'

@Component({ components: { Advert, Advert2,Advert3,Advert4,Advert5,Advert6 } })
export default class Index extends Vue {
  loaded: boolean = false // 页面是否已经加载完成
  slideIndex: number = 0 // 轮播图索引
  slideItemCount: number = 0 // 轮播图总个数
  slideWidth = document.documentElement.clientWidth
  slideAutoTimer = 0 // 自动播放定时器
  @Inject() appAuth!: (silent?: boolean) => Promise<{}>
  $refs!: {
    bannerContent: HTMLDivElement
  }
  clickSlideTo (index: number = this.slideIndex) {
    this.slideAutoNextStop()
    this.slideTo(index)
    this.slideAutoNextStart()
  }
  slideTo (index: number = this.slideIndex) {
    let content = document.querySelector('.banner-content') as HTMLDivElement
    content.style.left = index * -this.slideWidth + 'px'
    this.slideIndex = index
    document.querySelectorAll('.banner-nav').forEach((btn, i) => {
      if (i === index) {
        (btn as HTMLButtonElement).classList.add('active')
      } else {
        (btn as HTMLButtonElement).classList.remove('active')
      }
    })
  }
  slideAutoNextStart () {
    this.slideAutoTimer = window.setInterval(() => {
      let nextIndex = this.slideIndex + 1
      if (nextIndex > this.slideItemCount - 1) {
        // 超过最大值，重头开始播放
        nextIndex = 0
      }
      this.slideTo(nextIndex)
    }, 6000)
  }
  slideAutoNextStop () {
    clearInterval(this.slideAutoTimer)
  }
  slideInit () {
    this.slideAutoNextStop()
    let content = document.querySelector('.banner-content') as HTMLDivElement
    let items = content.querySelectorAll('.banner-item')
    // 清空旧状态
    content.style.width = ''
    items.forEach((item) => (item as HTMLDivElement).style.width = '')
    this.slideItemCount = items.length
    this.slideWidth = items[0].clientWidth
    // if (this.slideWidth < 1002) { this.slideWidth = 1002 }
    items.forEach((item) => (item as HTMLDivElement).style.width = this.slideWidth + 'px')
    content.style.width = items[0].clientWidth * items.length + 'px'
    this.slideTo()
    this.slideAutoNextStart()
  }
  to (path: string) {
    Lib.router.push(path)
  }
  created () {
    this.loaded = true
  }
  mounted () {
    Lib.dispatchEvent(document, 'custom-render-trigger')
    // log.debug('window.loaded')
    this.slideInit()
    window.addEventListener('resize', this.slideInit)
    document.querySelectorAll('.banner-nav').forEach(btn => {
      btn.addEventListener('click', (event) => {
        if (event.currentTarget) {
          // @ts-ignore
          this.clickSlideTo(Number(event.currentTarget.innerText) - 1)
        }
      })
    })
  }
}
