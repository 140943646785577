

























import { Component, Vue } from 'vue-property-decorator'
import { Route } from '../../node_modules/vue-router/types'

Component.registerHooks([ 'beforeRouteLeave' ])
@Component
export default class Login extends Vue {
  phone: string = ''
  captcha: string = ''
  account: string = ''
  password: string = ''
  loginIsLoading: boolean = false
  captchaInterval: number = 0
  captchaTimer: number = 0
  remember: boolean = true
  termsAgreed: boolean = false
  iframeBlank: string = app.static + 'blank.html'
  iframeSrc: string = this.iframeBlank
  isAccountLogin: boolean = true // 用帐号、密码方式登录
  $refs!: {
    phone: HTMLInputElement
  }
  login () {
    if (this.loginIsLoading) { return false }
    // event.preventDefault()
    if (this.isAccountLogin) {
      if (this.account === '') { return Lib.message(this, { type: 'error', message: '请输入帐号' }) }
      if (this.password === '') { return Lib.message(this, { type: 'error', message: '请输入密码' }) }
    } else {
      // 手机号码登录，验证手机号码
      // 手机号码校验 http://jackon.me/posts/validate-phone-number/
      if (!/^.{8,}$/.test(this.phone)) { return Lib.message(this, { type: 'error', message: '手机号输入错误，请更正' }) }
      // if (!/^\d{11}$/.test(this.phone)) { return Lib.message(this, { type: 'error', message: '手机号输入错误，请更正' }) }
      if (!/^\d{6}$/.test(this.captcha)) { return Lib.message(this, { type: 'error', message: '验证码输入错误，请更正' }) }
    }
    // if (!this.termsAgreed) { return Lib.message(this, { type: 'error', message: '请阅读《用户服务协议》及《隐私政策》，选择同意后登录' }) }
    log.debug(`[login] this.phone: "${this.phone}", this.captcha: "${this.captcha}"`)
    this.loginIsLoading = true
    let deviceArr = []
    deviceArr.push('cordova:' + device.cordova)
    deviceArr.push('model:' + device.model)
    deviceArr.push('platform:' + device.platform)
    deviceArr.push('version:' + device.version)
    deviceArr.push('manufacturer:' + device.manufacturer)
    deviceArr.push('isVirtual:' + device.isVirtual)
    deviceArr.push('serial:' + device.serial)
    let deviceStr = deviceArr.join(';')
    let query: SApiIF.User.LoginParam = {
      name: this.phone,
      // mobileCode: this.captcha,
      // password: 'e10adc3949ba59abbe56e057f20f883e',
      from: window.app.id,
      identity: 'S',
      version: window.app.version,
      device: deviceStr,
      deviceUuid: device.uuid
    }
    if (this.isAccountLogin) {
      query.name = this.account
      query.password = this.password
    } else {
      query.mobileCode = this.captcha
    }
    this.$store.dispatch('user/login', query).then((data) => {
      log.info(`[dispatch user/login] return data: ${data}`)
      this.loginSuccess()
    }).catch((ex) => {
      this.$message({ type: 'error', message: ex + ' ', duration: 16000, showClose: true })
      // Lib.message(this, { type: 'error', message: ex, duration: 0 })
      log.error(ex)
      this.loginIsLoading = false
    })
    // .then(() => {
    //   this.loginIsLoading = false
    // })
    return false
  }
  loginSuccess () {
    // 登录成功6秒后仍未跳转成功，让用户可以重新登录
    setTimeout(() => { this.loginIsLoading = false }, 6000)
    let redirect = this.$route.query.redirect || '/'
    if (redirect.startsWith('/login/')) {
      redirect = '/'
    }
    log.debug('[Login] redirect:', redirect)
    Lib.router.replace(redirect)
  }
  open (url: string) {
    this.iframeSrc = url
  }
  back () {
    log.debug('[Login back]')
    if (this.iframeSrc !== this.iframeBlank) { return this.iframeSrc = this.iframeBlank }
    SApi.recordAccess({
      gameName: 'teacher',
      gameVersion: app.release,
      gameParams: JSON.stringify({
        event: 'exit',
        router: {
          path: this.$route.path,
          query: this.$route.query
        }
      }),
      timestamp: new Date().getTime(),
      deviceUuid: device.uuid
    }).then(() => {
      log.debug('[Login back] exitApp')
      navigator.app.exitApp()
    }).catch(() => {
      log.debug('[Login back] exitApp')
      navigator.app.exitApp()
    })
  }
  getCaptcha () {
    if (!/^.{8,}$/.test(this.phone)) { return Lib.message(this, { type: 'error', message: '手机号输入错误，请更正' }) }
    if (this.captchaInterval) { return false }
    this.captchaInterval = 60
    SApi.User.getCaptcha({
      mobile: this.phone,
      type: 'login',
      device: '',
      deviceUuid: '',
      from: window.app.id
    }).then(() => {
      this.captchaTimer = window.setInterval(() => {
        this.captchaInterval--
        if (this.captchaInterval <= 0) { clearInterval(this.captchaTimer) }
      }, 1000)
    }).catch((err: string) => {
      Lib.message(this, { type: 'error', message: err })
      log.error('[login getCaptcha] err:' + err)
      this.captchaInterval = 0
    })
  }
  toggleMode () {
    this.isAccountLogin = !this.isAccountLogin
  }
  beforeCreate () {
    // 先检测是否已经在登录中心登录过
    log.debug('[login] 检测用户登录 user/check')
    this.$store.dispatch('user/check').then(data => {
      this.loginSuccess()
    }).catch((ex) => {
      log.warn(`用户未登录 ex：“${ex}”`)
    })
  }
  created () {
    // 绑定返回按钮事件
    document.removeEventListener('backbutton', Lib.router.backbuttonHandler)
    document.addEventListener('backbutton', this.back)
    // window.StatusBar.backgroundColorByHexString('#fff')
  }
  beforeRouteLeave (to: Route, from: Route, next: any) {
    document.removeEventListener('backbutton', this.back)
    document.addEventListener('backbutton', Lib.router.backbuttonHandler)
    log.debug('[Login beforeRouteLeave] removeEventListener backbutton')
    next()
  }
}
