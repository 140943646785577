/**
 * CordovaAudio 扩展类（扩展cordova的Media插件）
 * @version 1.0.1
 * @author JQ<jianqingchina@qq.com>
 * 2018/01/23
 */
import LProxy from './lProxy'
import Log from './log'
let log = new Log()

enum Status { None, Starting, Running, Paused, Stopped }

interface PreloadAudio {
  url: string
  promiser: {
    resolve: (val: CordovaAudio) => void
    reject: (err: any) => void
  }
}

export default class CordovaAudio {
  static all: CordovaAudio[] = []
  static preloadAudioQueue: PreloadAudio[] = []
  audio: any
  isLoaded: boolean = false
  volume: number = 1 // 音量：[0 - 1]
  private mediaResolve: Function | null = null
  private mediaStatusCore: Function = this.mediaStatusPreload
  constructor (url: string) {
    log.debug('[CordovaAudio constructor] CordovaAudio.all.length:', CordovaAudio.all.length)
    this.mediaSuccess = LProxy.proxy(this.mediaSuccess, this)
    this.mediaError = LProxy.proxy(this.mediaError, this)
    this.mediaStatus = LProxy.proxy(this.mediaStatus, this)
    this.audio = new Media(url, this.mediaSuccess, this.mediaError, this.mediaStatus)
    CordovaAudio.all.push(this)
    if (CordovaAudio.all.length > 30) { log.warn('[CordovaAudio constructor] 音频资源占用已到极限值31，安卓4.4设备上将无法再实例化音频资源，请释放（release）后操作再实例化音频。CordovaAudio.all.length:', CordovaAudio.all.length) }
  }
  // 实例化音频对象队列，安全创建方法
  static create (url: string): Promise<CordovaAudio> {
    return new Promise((resolve, reject) => {
      if (CordovaAudio.all.length < 30) {
        let audio = new CordovaAudio(url)
        return audio.preload().then(() => resolve(audio))
      }
      CordovaAudio.preloadAudioQueue.push({
        url,
        promiser: {
          resolve,
          reject
        }
      })
    })
  }
  // 清空等待实例化的音频对象列表
  static clearCreateQueue () {
    CordovaAudio.preloadAudioQueue = []
  }
  // 释放所有音频资源
  static allRelease () {
    CordovaAudio.all.forEach(audio => audio.release())
  }
  preload () {
    log.debug('[cordovaAudio preload] 开始音频预加载')
    return new Promise((resolve, reject) => {
      if (this.isLoaded) { return resolve() }
      this.mediaStatusCore = this.mediaStatusPreload
      this.mediaResolve = resolve
      if (window.app.platform !== 'browser') { this.audio.setVolume(0) }
      // this.audio.setVolume(0)
      this.audio.play()
    })
  }
  play (): Promise<boolean> {
    log.debug('[cordovaAudio play] 调用音频播放')
    // 是否暂停，resolve true为暂停，false 为正常播放完成
    return new Promise((resolve, reject) => {
      try {
        this.mediaStatusCore = this.mediaStatusPlay
        this.mediaResolve = resolve
        if (window.app.platform !== 'browser') { this.audio.setVolume(this.volume) }
        this.audio.play()
      } catch (err) {
        log.error('[CordovaAudio play] catch err:', err)
      }
    })
  }
  stop () {
    return this.audio.stop()
  }
  pause () {
    return this.audio.pause()
  }
  seekTo (milliseconds: number) {
    return this.audio.seekTo(milliseconds)
  }
  release () {
    // 释放资源，并更新静态属性all，所有音频列表
    this.audio.release()
    let index = CordovaAudio.all.findIndex(item => item === this)
    if (~index) { CordovaAudio.all.splice(index, 1) }
    // 检测是否有等待创建/预加载的音频，有就触发加载
    if (CordovaAudio.preloadAudioQueue.length) {
      let audio = new CordovaAudio(CordovaAudio.preloadAudioQueue[0].url)
      let promiser = CordovaAudio.preloadAudioQueue[0].promiser
      audio.preload().then(() => {
        CordovaAudio.preloadAudioQueue.shift()
        promiser.resolve(audio)
      }).catch(promiser.reject)
    }
  }
  private mediaSuccess () {
    log.debug('[cordovaAudio mediaSuccess]')
    return
  }
  private mediaError (err: any) {
    log.debug('[cordovaAudio mediaError] err:', err)
    return
  }
  private mediaStatus (status: Status) {
    this.mediaStatusCore(status)
  }
  private mediaStatusPreload (status: Status) {
    if (status === Status.Running) {
      log.debug('[cordovaAudio mediaStatusPreload] 音频预加载完成，但仍在处理预加载数据 status', status)
      this.isLoaded = true
      this.audio.stop()
    } else if (status === Status.Stopped) {
      log.debug('[cordovaAudio mediaStatusPreload] 音频预加载已处理完成', status)
      this.isLoaded = true // 预防跳过了runing 状态直接来到了stop状态，此处再设置一次loaded完成数据
      if (this.mediaResolve) { this.mediaResolve() }
    }
  }
  private mediaStatusPlay (status: Status) {
    if (status === Status.Paused) {
      log.debug('[cordovaAudio mediaStatusPlay] 音频播放完成，手动暂停 status', status)
      if (this.mediaResolve) { this.mediaResolve(true) } // 手动暂停，返回true
    } else if (status === Status.Stopped) {
      log.debug('[cordovaAudio mediaStatusPlay] 音频播放完成，播放至结尾 status', status)
      if (this.mediaResolve) { this.mediaResolve(false) }
    }
  }
}
