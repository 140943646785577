import { render, staticRenderFns } from "./Voice.vue?vue&type=template&id=d3b11446&scoped=true&"
import script from "./Voice.vue?vue&type=script&lang=ts&"
export * from "./Voice.vue?vue&type=script&lang=ts&"
import style0 from "./Voice.vue?vue&type=style&index=0&id=d3b11446&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3b11446",
  null
  
)

component.options.__file = "Voice.vue"
export default component.exports