/**
 * lib工具库，全局公用对象
 * @version 1.0.2
 * @author JQ<jianqingchina@qq.com>
 * 2017/05/06 - 2018/01/27
 */

import cookie from 'js-cookie'
// import { Howl } from 'howler'
import Cordova from './cordova'
import Url from './url'
import Log from './log'
import DeepCopy from './deepCopy'
import LProxy from './lProxy'
import { Vue } from 'vue/types/vue'
import VueRouter from 'vue-router'

type RouterRecordEvent = 'open' | 'back'

// let lib: any = {}
let log = new Log()
let routerOriginal: VueRouter
let routerRecordEvent: RouterRecordEvent = 'open'

class Lib {
  static store: any
  static vue: Vue
  static cookie = cookie
  static cordova = new Cordova()
  static log = log
  static Url = Url
  static proxy = LProxy.proxy
  static deepCopy = DeepCopy.copy
  // static clickAudio = new Howl({ src: [app.static + 'audio/click.mp3'] })
  static supports = {
    touch: !!('ontouchstart' in window) // || window.DocumentTouch && document instanceof DocumentTouch)
  }
  static router = {
    original: routerOriginal,
    recordEvent: routerRecordEvent,
    push (vueRouterOpt: any, eventName?: RouterRecordEvent) {
      log.debug('[Lib.router] push vueRouterOpt:', vueRouterOpt, 'eventName:', eventName)
      Lib.router.recordEvent = 'open'
      if (eventName) { Lib.router.recordEvent = eventName }
      this.original.push(vueRouterOpt)
    },
    go (num: number, eventName?: RouterRecordEvent) {
      log.debug('[Lib.router] go num:', num, 'eventName:', eventName)
      if (eventName) {
        Lib.router.recordEvent = eventName
      } else if (num < 0) {
        Lib.router.recordEvent = 'back'
      } else {
        Lib.router.recordEvent = 'open'
      }
      this.original.go(num)
    },
    replace (vueRouterOpt: any, eventName?: RouterRecordEvent) {
      log.debug('[Lib.router] replace vueRouterOpt:', vueRouterOpt, 'eventName:', eventName)
      Lib.router.recordEvent = 'open'
      if (eventName) { Lib.router.recordEvent = eventName }
      this.original.replace(vueRouterOpt)
    },
    back (eventName?: RouterRecordEvent) {
      log.debug('[Lib.router] back eventName:', eventName)
      Lib.router.recordEvent = 'back'
      if (eventName) { Lib.router.recordEvent = eventName }
      this.original.back()
    },
    backbuttonHandler () {
      log.debug('[Lib.router] backbuttonHandler')
      Lib.router.recordEvent = 'back'
      history.back()
    }
  }
  static devicereadyInit () {
    // 初始化函数
    log.debug('[Lib devicereadyInit]')
    document.addEventListener('backbutton', Lib.router.backbuttonHandler)
  }
  static message (context: Vue, opts: any) {
    return context.$message({
      message: opts.message,
      type: opts.type,
      showClose: true
    })
  }
  static alert (opts: any) {
    return Lib.vue.$alert(opts)
    // return new Promise((resolve, reject) => {
    //   window.alert(msg)
    //   resolve()
    // })
  }
  static confirm (context: Vue, msg: string, opts: any) {
    return context.$confirm(msg, opts)
  }

  /**
   * 为数字增加千分号
   * @param  {Number|String} num 需要处理的值
   * @return {String}     处理完成的值
   */
  static thousandBitSeparator (num: number | string) {
    return num.toString() && (num
      .toString().indexOf('.') !== -1 ? num.toString().replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
        return $1 + ','
      }) : num.toString().replace(/(\d)(?=(\d{3})+$)/g, function ($0, $1) {
        return $1 + ','
      }))
  }

  static loadScript (url: string) {
    log.debug('[Lib loadScript] run, url:', url)
    return new Promise((resolve, reject) => {
      let script = document.createElement('script')
      script.onload = resolve
      script.src = url
      let head = document.querySelector('head')
      if (head) {
        head.appendChild(script)
        log.debug('[Lib loadScript] 已添加Script脚本，待加载完成，url:', url)
      }
    })
  }

  // 此方法运行在Cordova初始化之前，所以不能依赖cordova的device组件
  static getPlatform () {
    let platform = Url.query('platform')
    if (platform !== undefined) { return platform }
    platform = sessionStorage.getItem('appPlatform')
    if (platform !== undefined || platform !== 'undefined') { return platform }
    if (navigator.userAgent.includes('Android')) {
      platform = 'android'
    } else if (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad')) {
      platform = 'ios'
    } else {
      platform = 'browser'
    }
    return platform
  }

  // 此方法运行在Cordova初始化之前，所以不能依赖cordova的device组件
  static getVersion () {
    let version = Url.query('ver')
    let platform = app.platform
    if (!platform) { platform = Lib.getPlatform() }
    if (!version) { version = sessionStorage.getItem('appVersion') }
    if (version === undefined || version === 'undefined') { version = platform === 'android' ? '1.0.0' : '1.0.1' }
    return version
  }

  static inViewport (ele: Element | null): boolean {
    if (!ele) { return false }
    let rect = ele.getBoundingClientRect()
    if (rect.width === 0 && rect.height === 0) {
      return false
    }
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  static eleOuterWidth (ele: Element): number {
    // width + padding + margin
    let eleStyle = window.getComputedStyle(ele)
    let margin = parseFloat(eleStyle.getPropertyValue('margin-left')) + parseFloat(eleStyle.getPropertyValue('margin-right'))
    return (ele.getBoundingClientRect().width + margin)
  }

  static eleOuterHeight (ele: Element): number {
    // height + padding + margin
    let eleStyle = window.getComputedStyle(ele)
    let margin = parseFloat(eleStyle.getPropertyValue('margin-top')) + parseFloat(eleStyle.getPropertyValue('margin-bottom'))
    return (ele.getBoundingClientRect().height + margin)
  }

  static catchError (err: string | Error) {
    if (Lib.isError(err)) {
      log.error(err.message)
      Lib.alert(err.message).catch()
    } else {
      log.error(err)
      Lib.alert(err).catch()
    }
  }

  static isError (err: any): err is Error {
    let isIt = false
    if (err && err.message && err.name) {
      isIt = true
    }
    return isIt
  }

  static randomInt (min: number, max?: number) {
    if (max === undefined) {
      max = min
      min = 0
    }
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  static isPromiseLike<T> (val: any): val is PromiseLike<T> {
    let isIt = false
    if (val && val.then && val.catch) {
      isIt = true
    }
    return isIt
  }

  static promiseAll<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10> (promises: [T1 | PromiseLike<T1>, T2 | PromiseLike<T2>, T3 | PromiseLike<T3>, T4 | PromiseLike<T4>, T5 | PromiseLike<T5>, T6 | PromiseLike<T6>, T7 | PromiseLike<T7>, T8 | PromiseLike<T8>, T9 | PromiseLike<T9>, T10 | PromiseLike<T10>]): Promise<[T1, T2, T3, T4, T5, T6, T7, T8, T9, T10]>
  static promiseAll<T1, T2, T3, T4, T5, T6, T7, T8, T9> (promises: [T1 | PromiseLike<T1>, T2 | PromiseLike<T2>, T3 | PromiseLike<T3>, T4 | PromiseLike<T4>, T5 | PromiseLike<T5>, T6 | PromiseLike<T6>, T7 | PromiseLike<T7>, T8 | PromiseLike<T8>, T9 | PromiseLike<T9>]): Promise<[T1, T2, T3, T4, T5, T6, T7, T8, T9]>
  static promiseAll<T1, T2, T3, T4, T5, T6, T7, T8> (promises: [T1 | PromiseLike<T1>, T2 | PromiseLike<T2>, T3 | PromiseLike<T3>, T4 | PromiseLike<T4>, T5 | PromiseLike<T5>, T6 | PromiseLike<T6>, T7 | PromiseLike<T7>, T8 | PromiseLike<T8>]): Promise<[T1, T2, T3, T4, T5, T6, T7, T8]>
  static promiseAll<T1, T2, T3, T4, T5, T6, T7> (promises: [T1 | PromiseLike<T1>, T2 | PromiseLike<T2>, T3 | PromiseLike<T3>, T4 | PromiseLike<T4>, T5 | PromiseLike<T5>, T6 | PromiseLike<T6>, T7 | PromiseLike<T7>]): Promise<[T1, T2, T3, T4, T5, T6, T7]>
  static promiseAll<T1, T2, T3, T4, T5, T6> (promises: [T1 | PromiseLike<T1>, T2 | PromiseLike<T2>, T3 | PromiseLike<T3>, T4 | PromiseLike<T4>, T5 | PromiseLike<T5>, T6 | PromiseLike<T6>]): Promise<[T1, T2, T3, T4, T5, T6]>
  static promiseAll<T1, T2, T3, T4, T5> (promises: [T1 | PromiseLike<T1>, T2 | PromiseLike<T2>, T3 | PromiseLike<T3>, T4 | PromiseLike<T4>, T5 | PromiseLike<T5>]): Promise<[T1, T2, T3, T4, T5]>
  static promiseAll<T1, T2, T3, T4> (promises: [T1 | PromiseLike<T1>, T2 | PromiseLike<T2>, T3 | PromiseLike<T3>, T4 | PromiseLike<T4>]): Promise<[T1, T2, T3, T4]>
  static promiseAll<T1, T2, T3> (promises: [T1 | PromiseLike<T1>, T2 | PromiseLike<T2>, T3 | PromiseLike<T3>]): Promise<[T1, T2, T3]>
  static promiseAll<T1, T2> (promises: [T1 | PromiseLike<T1>, T2 | PromiseLike<T2>]): Promise<[T1, T2]>
  static promiseAll<TAll> (promises: Iterable<TAll | PromiseLike<TAll>>): Promise<TAll[]>
  static promiseAll<T> (promises: (T | PromiseLike<T>)[]): Promise<T[]> {
    // Typescript 2.7.1 的 Promise.all在安卓4.4存在兼容问题，手动实现兼容
    // return Promise.all(promises)
    return new Promise((resolve, reject) => {
      let count = promises.length
      let result: T[] = []
      let checkDone = () => { if (--count === 0) resolve(result) }
      if (!promises.length) { return resolve([]) }
      promises.forEach((promise, i) => {
        if (Lib.isPromiseLike<T>(promise)) {
          promise.then(val => {
            result[i] = val
          }, reject).then(checkDone)
        } else {
          result[i] = promise
          checkDone()
        }
      })
    })
  }

  static findLast<T> (array: T[], callback: (element: T, index: number, array: T[]) => boolean, thisArg?: any): T | undefined {
    if (!array || array.length < 1) { return undefined }
    for (let i = array.length - 1; i > -1; i--) {
      if (thisArg) {
        if (callback.call(thisArg, array[i], i, array)) {
          return array[i]
        }
      } else if (callback(array[i], i, array)) {
        return array[i]
      }
    }
    return undefined
  }

  static findLastIndex<T> (array: T[], callback: (element: T, index: number, array: T[]) => boolean, thisArg?: any): number {
    if (!array || array.length < 1) { return -1 }
    for (let i = array.length - 1; i > -1; i--) {
      if (thisArg) {
        if (callback.call(thisArg, array[i], i, array)) {
          return i
        }
      } else if (callback(array[i], i, array)) {
        return i
      }
    }
    return -1
  }

  // 版本比较 ver0 > ver1
  static versionGreater (ver0: string, ver1: string) {
    let ver0Arr = ver0.split('.')
    let ver1Arr = ver1.split('.')
    let len = Math.max(ver0Arr.length, ver1Arr.length)
    for (let i = 0; i < len; i++) {
      let var0Int = parseInt(ver0Arr[i], 10)
      let var1Int = parseInt(ver1Arr[i], 10)
      if (isNaN(var0Int)) { var0Int = 0 }
      if (isNaN(var1Int)) { var1Int = 0 }
      if (var0Int > var1Int) {
        return true
      } else if (var0Int < var1Int) {
        return false
      }
    }
    return false
  }

  // 版本比较 ver0 < ver1
  static versionLess (ver0: string, ver1: string) {
    let ver0Arr = ver0.split('.')
    let ver1Arr = ver1.split('.')
    let len = Math.max(ver0Arr.length, ver1Arr.length)
    for (let i = 0; i < len; i++) {
      let var0Int = parseInt(ver0Arr[i], 10)
      let var1Int = parseInt(ver1Arr[i], 10)
      if (isNaN(var0Int)) { var0Int = 0 }
      if (isNaN(var1Int)) { var1Int = 0 }
      if (var0Int < var1Int) {
        return true
      } else if (var0Int > var1Int) {
        return false
      }
    }
    return false
  }

  // 兼容IE的自定义事件
  static dispatchEvent (ele: Element | Document, eventName: string) {
    let event
    // tslint:disable-next-line
    if (typeof Event === 'function') {
      event = new Event(eventName)
    } else {
      event = document.createEvent('Event')
      event.initEvent(eventName, true, true)
    }
    ele.dispatchEvent(event)
  }
}

export default Lib
