/**
 * Server API，数据请求链接
 * @version 1.0.1
 * @author JQ<jianqingchina@qq.com>
 * 2018/02/09 - 2018/09/03
 */
// import Lib from '../plugins/lib'

export default class Urls {
  static readonly forceProduction: boolean = false // 强制切换至生产环境
  static readonly forceDebug = (() => {
    // 判断使用内网还是外网SAPI接口
    if (app.runEnv === 'dev') {
      console.info('[SApi.Urls] 检测到本地运行环境参数，使用内网SApi接口 forceDebug = true, app.runEnv:', app.runEnv)
      return true
    }
    return false
  })()
  static readonly production = {
    APP: 'https://shansuan.doumiao9.com/app/',
    APP_API: 'https://api.doumiao9.com/shansuan/t/',
    SHANSUAN_API: 'https://api.doumiao9.com/shansuan/',
    STUDENT_API: 'https://api.doumiao9.com/shansuan/s/',
    COM: 'https://api.doumiao9.com/commons/',
    BASE: 'https://api.doumiao9.com/base/',
    USER: 'https://login.doumiao9.com/',
    CHUTI: 'https://api.doumiao9.com/dmabacus/',
    TTS: 'https://api.doumiao9.com/dmabacus/',
    PUBLIC: 'https://s.doumiao9.com/public/',
    OFFICIAL: 'https://api.doumiao9.com/offical/'
  }
  static readonly debug = {
    APP: 'http://192.168.9.9:9001/app/',
    APP_API: 'http://192.168.9.9:8000/shansuan/t/',
    SHANSUAN_API: 'http://192.168.9.9:8000/shansuan/',
    STUDENT_API: 'http://192.168.9.9:8000/shansuan/s/',
    COM: 'http://192.168.9.9:8000/commons/',
    BASE: 'http://192.168.9.9:8000/base/',
    USER: 'http://192.168.9.9:8010/',
    CHUTI: 'http://192.168.9.9:8000/dmabacus/',
    TTS: 'https://api.doumiao9.com/dmabacus/',
    PUBLIC: 'http://192.168.9.9:9000/public/',
    OFFICIAL: 'http://192.168.9.8:8090/'
  }
  static readonly use = (Urls.forceProduction || (!Urls.forceDebug && process.env.NODE_ENV === 'production')) ? Urls.production : Urls.debug
  // URLS
  static readonly LOGIN = Urls.use.USER + 'login'
  static readonly USER_LOGOUT = Urls.use.USER + 'exit'
  static readonly USER_INFO = Urls.use.USER + 'getauth'
  static readonly USER_CHECK = Urls.use.USER + 'check'
  static readonly USER_UPDATE_INFO = Urls.use.USER + 'upd_user'
  static readonly GET_CAPTCHA = Urls.use.COM + 'send_mobile_code'
  static readonly SET_DATA = Urls.use.COM + 'set_shared_val'
  static readonly GET_DATA = Urls.use.COM + 'get_shared_val'
  static readonly QUESTIONS = Urls.use.CHUTI + 'level_questions'
  static readonly QUESTIONS_DATA = Urls.use.CHUTI + 'get_req_ques_data'
  static readonly ONLINE_TTS = Urls.use.TTS + 'online_tts'
  static readonly STANDARDS = Urls.use.SHANSUAN_API + 'abacus_versions'
  static readonly RECORD_ACCESS = Urls.use.SHANSUAN_API + 'add_game_access'
  static readonly ADD_ABACUS_FEEDBACK = Urls.use.OFFICIAL + 'add_abacus_feedback'
}
