import { render, staticRenderFns } from "./InstallKetang.vue?vue&type=template&id=48b60812&scoped=true&"
import script from "./InstallKetang.vue?vue&type=script&lang=ts&"
export * from "./InstallKetang.vue?vue&type=script&lang=ts&"
import style0 from "./InstallKetang.vue?vue&type=style&index=0&id=48b60812&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48b60812",
  null
  
)

component.options.__file = "InstallKetang.vue"
export default component.exports