


















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Advert extends Vue {
  pages: boolean = false
  realname: string = '' // 姓名
  mobile: string = '' // 电话号码
  suggest: string = ''   // 内容
  btnAdv () {
    this.pages = true
  }
  btnDel () {
    this.pages = false
  }
  verify () {
    let realname = this.realname
    let mobile = this.mobile
    let suggest = this.suggest
    if (!realname) {
      alert('请填您的姓名')
      return null
    }
    realname = realname.trim()
    if (!realname) {
      alert('请填您的姓名')
      return null
    }

    if (!mobile) {
      alert('请填您的手机')
      return null
    }
    mobile = mobile.trim()
    if (mobile.length < 6) {
      alert('您的手机号过短，请更正')
      return null
    }
    if (!/^[\d\- ]*$/.test(mobile)) {
      alert('您的手机号有字母等其他字符，请更正')
      return null
    }
    if (!suggest) {
      alert('请写您想学的知识点')
      return null
    }
    suggest = suggest.trim()
    if (suggest.length < 1) {
      alert('请写您想学的知识点')
      return null
    }
    let query: SApiIF.FeedBackParam = {
      realname: realname,
      mobile: mobile,
      suggest: suggest
    }
    return query
  }
  submit () {
    let query: SApiIF.FeedBackParam | null = this.verify()
    if (query) {
      SApi.feedBack(query).then(res => {
        console.log(this.realname)
        alert('提交成功！')
      }).catch()
    }
  }
  mounted () {
    let text = document.querySelector('textarea')
    if (text) {
      text.focus()
    }
    // document.querySelector('textarea').focus()
    let content = document.querySelector('.advert3-content')
    let close = document.querySelector('.advert3-close')
    if (close) {
      close.addEventListener('click', (event) => {
        event.stopPropagation()
        event.preventDefault()
        if (content) {
          content.classList.add('close')
        // this.$store.commit('advert/setQuery', { click: true })
        }
      })
    }

    // if (content) {
    //   content.addEventListener('click', (event) => {
    //     event.preventDefault()
    //     this.btnAdv()
    //   })
    // }
  }
}
