/**
 * SAPI，数据请求交互层，用户登录公共部分
 * @version 1.0.1
 * @author JQ<jianqingchina@qq.com>
 * 2017/12/08 - 2018/02/08
 */

import Urls from '../urls'
import Core from '../core'

export default class User {
  /**
   * 登录，公共登录中心的登录
   * @param  {object} data 登录信息除外，请求需要用的参数
   * @return {Promise}
   */
  static login (data: SApiIF.User.LoginParam, opts?: SApiIF.OptsParam): Promise<SApiIF.User.Login> {
    opts = Object.assign({
      URL: Urls.LOGIN,
      data,
      silent: true,
      keyWord: '登录'
    }, opts)
    return Core.jsonp(opts)
  }
  /**
   * 获取用户信息
   * @param  {object} data 登录信息除外，请求需要用的参数
   * @return {Promise}
   */
  static userInfo (opts?: SApiIF.OptsParam): Promise<SApiIF.User.UserInfo> {
    opts = Object.assign({
      URL: Urls.USER_INFO,
      silent: true,
      keyWord: '获取用户信息'
    }, opts)
    return Core.jsonp(opts)
  }
  /**
   * 获取用户登录状态
   * @param  {object} data 登录信息除外，请求需要用的参数
   * @return {Promise}
   */
  static userCheck (data?: SApiIF.User.CheckParam, opts?: SApiIF.OptsParam): Promise<SApiIF.User.UserCheck> {
    let generateOpts = Core.assign({
      URL: Urls.USER_CHECK,
      silent: true,
      data,
      keyWord: '获取用户登录状态'
    }, opts)
    return Core.jsonp(generateOpts)
  }
  /**
   * 退出登录
   * @param  {object} data 登录信息除外，请求需要用的参数
   * @return {Promise}
   */
  static logout (opts?: SApiIF.OptsParam): Promise<SApiIF.Status> {
    opts = Object.assign({
      URL: Urls.USER_LOGOUT,
      keyWord: '退出登录'
    }, opts)
    return Core.jsonp(opts)
  }
  /**
   * 发送验证码
   * @param  {object} data 登录信息除外，请求需要用的参数
   * @return {Promise}
   */
  static getCaptcha (data: SApiIF.User.GetCaptchaParam, opts?: SApiIF.OptsParam): Promise<SApiIF.Status> {
    let generateOpts = Core.assign({
      URL: Urls.GET_CAPTCHA,
      silent: true,
      data,
      keyWord: '发送验证码'
    }, opts)
    return Core.post(generateOpts)
  }
  // 更新/修改用户信息
  static updateInfo (data: SApiIF.User.UpdateInfoParam, opts?: SApiIF.OptsParam): Promise<SApiIF.Status> {
    let generateOpts = Core.assign({
      URL: Urls.USER_UPDATE_INFO,
      data,
      keyWord: '更新用户信息'
    }, opts)
    return Core.post(generateOpts)
  }
}
