/**
 * proxy 代理工具类，类似jQuery.proxy
 * @version 1.0.0
 * @author JQ<jianqingchina@qq.com>
 * 2018/01/20
 */

export default class LProxy {
  static proxy (fn: Function, context: Object, ...args: any[]) {
    // let slice = Array.prototype.slice
    // let args = slice.call(arguments, 2)
    // return fn.apply(context || this, args.concat(slice.call(arguments)))
    let _args = Array.prototype.slice.call(arguments, 2)
    return function () {
      console.log('_args:', _args)
      fn.apply(context, _args)
    }
  }
}
