/**
 * URL处理工具类
 * @version 1.0.2
 * @author JQ<jianqingchina@qq.com>
 * 2017/12/07 - 2018/01/27
 */

import queryString from 'query-string'

export default class Url {
  url: string = window.location.href
  query: any
  constructor (url: string = window.location.href) {
    // this.query = queryString.parse(this.url)
  }
  static stringify (_url: string, params?: StringMap) {
    let param = ''
    if (params) {
      param += '?' + Url.encoded(params)
    }
    return _url + param
  }
  /**
   * url参数传输处理
   * @param  {object[]} params 要处理的参数对象
   * @return {string}          适用于提交的数据
   */
  static encoded (params: StringMap) {
    return Object.keys(params).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    }).join('&')
  }
  static query (key?: string, val?: string) {
    let search = window.location.search
    if (!search && window.location.hash) {
      let index = window.location.hash.indexOf('?')
      if (index !== -1) {
        search = window.location.hash.slice(index)
      }
    }
    if (!search) { return }
    if (key !== undefined) {
      return queryString.parse(decodeURIComponent(search))[key]
    } else {
      return queryString.parse(decodeURIComponent(search))
    }
  }
}
