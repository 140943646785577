import { render, staticRenderFns } from "./Cube.vue?vue&type=template&id=50dc50c7&scoped=true&"
import script from "./Cube.vue?vue&type=script&lang=ts&"
export * from "./Cube.vue?vue&type=script&lang=ts&"
import style0 from "./Cube.vue?vue&type=style&index=0&id=50dc50c7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50dc50c7",
  null
  
)

component.options.__file = "Cube.vue"
export default component.exports