import { render, staticRenderFns } from "./GuideKetang.vue?vue&type=template&id=0abf9b40&scoped=true&"
import script from "./GuideKetang.vue?vue&type=script&lang=ts&"
export * from "./GuideKetang.vue?vue&type=script&lang=ts&"
import style0 from "./GuideKetang.vue?vue&type=style&index=0&id=0abf9b40&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0abf9b40",
  null
  
)

component.options.__file = "GuideKetang.vue"
export default component.exports