
































import { Vue, Component, Inject } from 'vue-property-decorator'
// @ts-ignore
import { Slider, SliderItem } from 'vue-easy-slider'

@Component({ components: { Slider, SliderItem } })
export default class Titbits extends Vue {
  sliderIndex: number = 0
  sliderValue: number = 0
  staticPath: string = 'https://s.doumiao9.com/official'
  @Inject() appAuth!: (silent?: boolean) => Promise<{}>
  setActiveTtem (i: number) {
    this.videoPause()
    document.querySelectorAll('.sliderItem').forEach((wrapEle,index) => {
      if (index === this.sliderIndex) {
        wrapEle.classList.add('show-video')
      } else {
        wrapEle.classList.remove('show-video')
      }
    })
  }
  videoPause () {
    let videos = document.querySelectorAll('.main-video')
    if (videos) {
      videos.forEach((video: any) => {
        video.pause()
      })
    }
  }
  mounted () {
    document.querySelectorAll('.sx-video').forEach((j,index) => {
      j.addEventListener('click', event => {
        this.sliderIndex = index
        event.preventDefault()
        this.setActiveTtem(index)
      })
    })
    this.setActiveTtem(0)
  }
}
