import { render, staticRenderFns } from "./TrainVideo.vue?vue&type=template&id=f5f3fb64&scoped=true&"
import script from "./TrainVideo.vue?vue&type=script&lang=ts&"
export * from "./TrainVideo.vue?vue&type=script&lang=ts&"
import style0 from "./TrainVideo.vue?vue&type=style&index=0&id=f5f3fb64&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5f3fb64",
  null
  
)

component.options.__file = "TrainVideo.vue"
export default component.exports