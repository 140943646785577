import { render, staticRenderFns } from "./FQA.vue?vue&type=template&id=55b0e472&scoped=true&"
import script from "./FQA.vue?vue&type=script&lang=ts&"
export * from "./FQA.vue?vue&type=script&lang=ts&"
import style0 from "./FQA.vue?vue&type=style&index=0&id=55b0e472&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55b0e472",
  null
  
)

component.options.__file = "FQA.vue"
export default component.exports