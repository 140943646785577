

























































































































































































































































































































































import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import Speech from '@/components/StudentSpeech/Speech.vue'
import Titbits from '@/components/StudentSpeech/Titbits.vue'
import TrainVideo from '@/components/StudentSpeech/TrainVideo.vue'
@Component({ components: { Speech, Titbits, TrainVideo } })
export default class Trained extends Vue {
  loaded: boolean = false // 页面是否已经加载完成
  realname: string = ''
  mobile: string = ''
  data: string = ''   // 培训时间
  dataPrimary: Boolean = false   // 培训时间
  dataIntermediate: Boolean = false    // 培训时间
  dataSenior: Boolean = false    // 培训时间
  suggest: string = ''
  slideIndex: number = 0 // 轮播图索引
  slideItemCount: number = 0 // 轮播图总个数
  slideWidth = document.documentElement.clientWidth
  slideAutoTimer = 0 // 自动播放定时器
  @Inject() appAuth!: (silent?: boolean) => Promise<{}>
  $refs!: {
    bannerContent: HTMLDivElement
    titbits: Titbits
  }
  clickSlideTo (index: number = this.slideIndex) {
    this.slideAutoNextStop()
    this.slideTo(index)
    this.slideAutoNextStart()
  }
  slideTo (index: number = this.slideIndex) {
    let content = document.querySelector('.banner-content') as HTMLDivElement
    content.style.left = index * -this.slideWidth + 'px'
    this.slideIndex = index
    document.querySelectorAll('.banner-nav').forEach((btn, i) => {
      if (i === index) {
        (btn as HTMLButtonElement).classList.add('active')
      } else {
        (btn as HTMLButtonElement).classList.remove('active')
      }
    })
  }
  slideAutoNextStart () {
    this.slideAutoTimer = window.setInterval(() => {
      let nextIndex = this.slideIndex + 1
      if (nextIndex > this.slideItemCount - 1) {
        // 超过最大值，重头开始播放
        nextIndex = 0
      }
      this.slideTo(nextIndex)
    }, 6000)
  }
  slideAutoNextStop () {
    clearInterval(this.slideAutoTimer)
  }
  slideInit () {
    this.slideAutoNextStop()
    let content = document.querySelector('.banner-content') as HTMLDivElement
    let items = content.querySelectorAll('.banner-item')
    // 清空旧状态
    content.style.width = ''
    items.forEach((item) => (item as HTMLDivElement).style.width = '')
    this.slideItemCount = items.length
    this.slideWidth = items[0].clientWidth
    // if (this.slideWidth < 1002) { this.slideWidth = 1002 }
    items.forEach((item) => (item as HTMLDivElement).style.width = this.slideWidth + 'px')
    content.style.width = items[0].clientWidth * items.length + 'px'
    this.slideTo()
    this.slideAutoNextStart()
  }
  verify () {
    let realname = (document.querySelector('.ipt-realname') as HTMLInputElement).value
    let mobile = (document.querySelector('.ipt-mobile') as HTMLInputElement).value
    let suggest = (document.querySelector('.text-suggest') as HTMLTextAreaElement).value
    if (!realname) {
      alert('请填您的姓名')
      return null
    }
    realname = realname.trim()
    if (!realname) {
      alert('请填您的姓名')
      return null
    }

    if (!mobile) {
      alert('请填您的手机')
      return null
    }
    mobile = mobile.trim()
    if (mobile.length < 6) {
      alert('您的手机号过短，请更正')
      return null
    }
    if (!/^[\d\- ]*$/.test(mobile)) {
      alert('您的手机号有字母等其他字符，请更正')
      return null
    }
    if (!suggest) {
      alert('请写您想学的知识点')
      return null
    }
    suggest = suggest.trim()
    if (suggest.length < 1) {
      alert('请写您想学的知识点')
      return null
    }
    let query: SApiIF.FeedBackParam = {
      realname: realname,
      mobile: mobile,
      suggest: suggest
    }
    return query
  }
  submit () {
    let query: SApiIF.FeedBackParam | null = this.verify()
    if (query) {
      SApi.feedBack(query).then(res => {
        console.log(this.realname)
        alert('提交成功！')
      }).catch()
    }
  }
  tabPrimary () {
    let tabPrimary = document.querySelector('.tab-primary')
    if (!tabPrimary) { return }
    tabPrimary.classList.add('show')
  }
  tabIntermediate () {
    let tabIntermediate = document.querySelector('.tab-intermediate')
    if (!tabIntermediate) { return }
    tabIntermediate.classList.add('show')
  }
  tabSenior () {
    let tabSenior = document.querySelector('.tab-senior')
    if (!tabSenior) { return }
    tabSenior.classList.add('show')
  }
  closeTab () {
    let tabPrimary: any = document.querySelector('.tab-primary')
    let tabIntermediate: any = document.querySelector('.tab-intermediate')
    let tabSenior: any = document.querySelector('.tab-senior')
    tabPrimary.classList.remove('show')
    tabIntermediate.classList.remove('show')
    tabSenior.classList.remove('show')
  }
  showBox1 () {
    let course = document.querySelector('.course')
    let speech: any = document.querySelector('.speech')
    let titbits: any = document.querySelector('.titbits')
    let trainVideo: any = document.querySelector('.train-video')
    if (!course) { return }
    course.classList.remove('show')
    speech.classList.remove('show')
    titbits.classList.remove('show')
    trainVideo.classList.remove('show')
    this.$refs.titbits.videoPause()
  }
  showBox3 () {
    let course: any = document.querySelector('.course')
    let speech = document.querySelector('.speech')
    let titbits: any = document.querySelector('.titbits')
    let trainVideo: any = document.querySelector('.train-video')
    if (!speech) { return }
    speech.classList.add('show')
    course.classList.add('show')
    titbits.classList.remove('show')
    trainVideo.classList.remove('show')
    this.$refs.titbits.videoPause()
  }
  showBox4 () {
    let course: any = document.querySelector('.course')
    let speech: any = document.querySelector('.speech')
    let titbits = document.querySelector('.titbits')
    let trainVideo: any = document.querySelector('.train-video')
    if (!titbits) { return }
    titbits.classList.add('show')
    speech.classList.remove('show')
    trainVideo.classList.remove('show')
    course.classList.add('show')
  }
  showBox2 () {
    let course: any = document.querySelector('.course')
    let speech: any = document.querySelector('.speech')
    let titbits = document.querySelector('.titbits')
    let trainVideo: any = document.querySelector('.train-video')
    if (!titbits) { return }
    trainVideo.classList.add('show')
    titbits.classList.remove('show')
    speech.classList.remove('show')
    course.classList.add('show')
  }
  showSignUp () {
    let signupOverlay = document.querySelector('.sign-up-overlay')
    if (!signupOverlay) { return }
    signupOverlay.classList.add('show')
  }
  hideSignUp () {
    let signupOverlay = document.querySelector('.sign-up-overlay')
    if (!signupOverlay) { return }
    signupOverlay.classList.remove('show')
  }
  showDetails () {
    let detailsOverlay = document.querySelector('.details-overlay')
    if (!detailsOverlay) { return }
    detailsOverlay.classList.add('show')
  }
  hideDetails () {
    let detailsOverlay = document.querySelector('.details-overlay')
    if (!detailsOverlay) { return }
    detailsOverlay.classList.remove('show')
  }
  created () {
    this.loaded = true
  }
  externalAccessHandle (type: string) {
    if (!type) return
    let el: any = document.querySelector('.side-nav-item2')
    if (type === 'xspx') {
      el.click()
      let el1: any = document.querySelector('.nav-item1')
      setTimeout(() => {
        el1.click()
      }, 200)
    } else if (type === 'yctfx') {
      el.click()
      let el1: any = document.querySelector('.nav-item2')
      setTimeout(() => {
        el1.click()
      }, 200)
    }
  }
  mounted () {
    Lib.dispatchEvent(document, 'custom-render-trigger')
    let btnClose = document.querySelector('.btn-consult3')
    let btnSubmit = document.querySelector('.btn-consult4')
    let overlay = document.querySelector('.overlay')
    let btnCloseImg = document.querySelector('.table-img-consult')
    let tableImbBox = document.querySelector('.table-img-box')
    this.slideInit()
    window.addEventListener('resize', this.slideInit)
    document.querySelectorAll('.banner-nav').forEach(btn => {
      btn.addEventListener('click', (event) => {
        if (event.currentTarget) {
          // @ts-ignore
          this.clickSlideTo(Number(event.currentTarget.innerText) - 1)
        }
      })
    })
    if (btnClose) {
      btnClose.addEventListener('click', (event) => {
        event.preventDefault()
        if (overlay) {
          overlay.classList.add('hide')
        }
      })
    }
    if (btnCloseImg) {
      btnCloseImg.addEventListener('click', (event) => {
        event.preventDefault()
        if (tableImbBox) {
          tableImbBox.classList.add('hide')
        }
      })
    }
    if (btnSubmit) {
      btnSubmit.addEventListener('click', (event) => {
        event.preventDefault()
        this.submit()
      })
    }
    let packBtn = document.querySelectorAll('.pack-btn')
    let packBtn2 = document.querySelectorAll('.pack-btn2')
    let btnSignUpList = document.querySelectorAll('.btn')
    // 左侧导航
    let isSideNavItem: Boolean = false
    let sideNav = document.querySelector('.side-nav')
    document.querySelectorAll('.side-nav-item').forEach((item, index, data) => {
      if (isSideNavItem) { return } // 防止重复添加点击事件
      if (index === data.length - 1) { isSideNavItem = true }
      let _this = this
      item.addEventListener('click', () => {
        for (let x = 0; x < data.length; x++) {
          let n = x + 1
          data[x].classList.remove(`side-nav-item${n}-un-active`)
          data[x].classList.remove(`side-nav-item${n}-active`)
          data[x].classList.add(`side-nav-item${n}-un-active`)
        }
        let j: any = item.getAttribute('index')
        item.classList.remove(`side-nav-item${j}-un-active`)
        item.classList.add(`side-nav-item${j}-active`)
        // 显示对应的
        if (j === '1') {
          if (sideNav) {
            sideNav.classList.remove(`top-h`)
            sideNav.classList.add(`top-h`)
          }
          _this.showBox1()
        } else if (j === '2') {
          if (sideNav) {
            sideNav.classList.remove(`top-h`)
          }
          _this.showBox2()
        } else if (j === '3') {
          if (sideNav) {
            sideNav.classList.remove(`top-h`)
          }
          _this.showBox3()
        } else {
          if (sideNav) {
            sideNav.classList.remove(`top-h`)
          }
          _this.showBox4()
        }
      })
    })
    let btnSignUpClose = document.querySelector('.sign-up-close')
    let btnDetailsClose = document.querySelector('.details-close')
    let tab1 = document.querySelector('.tab1')
    let tab2 = document.querySelector('.tab2')
    let tab3 = document.querySelector('.tab3')
    let tab4 = document.querySelector('.tab4')
    let tab11 = document.querySelector('.tab1-1')
    let tab22 = document.querySelector('.tab2-2')
    let tab33 = document.querySelector('.tab3-3')
    let signupOverlay = document.querySelector('.sign-up-overlay')
    if (btnSignUpList) {
      btnSignUpList.forEach(btnSignUp => {
        btnSignUp.addEventListener('click', (event) => {
          event.preventDefault()
          this.showSignUp()
        })
      })
    }
    if (packBtn) {
      packBtn.forEach(btnSignUp => {
        btnSignUp.addEventListener('click', (event) => {
          event.preventDefault()
          this.showSignUp()
        })
      })
    }
    if (tab1) {
      tab1.addEventListener('click', (event) => {
        event.preventDefault()
        this.tabPrimary()
      })
    }
    if (tab2) {
      tab2.addEventListener('click', (event) => {
        event.preventDefault()
        this.tabIntermediate()
      })
    }
    if (tab3) {
      tab3.addEventListener('click', (event) => {
        event.preventDefault()
        this.tabSenior()
      })
    }
    if (tab11) {
      tab11.addEventListener('click', (event) => {
        event.preventDefault()
        this.tabPrimary()
      })
    }
    if (tab22) {
      tab22.addEventListener('click', (event) => {
        event.preventDefault()
        this.tabIntermediate()
      })
    }
    if (tab33) {
      tab33.addEventListener('click', (event) => {
        event.preventDefault()
        this.tabSenior()
      })
    }
    if (tab4) {
      tab4.addEventListener('click', (event) => {
        event.preventDefault()
      })
    }
    if (packBtn2) {
      packBtn2.forEach(btnDetails => {
        btnDetails.addEventListener('click', (event) => {
          event.preventDefault()
          this.showDetails()
        })
      })
    }
    if (btnSignUpClose) {
      btnSignUpClose.addEventListener('click', (event) => {
        event.preventDefault()
        this.hideSignUp()
      })
    }
    if (btnDetailsClose) {
      btnDetailsClose.addEventListener('click', (event) => {
        event.preventDefault()
        this.hideDetails()
        this.closeTab()
      })
    }
    if (window.location.search.includes('xspx')) {
      this.externalAccessHandle('xspx')
    } else if (window.location.search.includes('yctfx')) {
      this.externalAccessHandle('yctfx')
    }
  }
}
