/**
 * SAPI，数据请求交互层，用户登录公共部分
 * @version 1.0.1
 * @author JQ<jianqingchina@qq.com>
 * 2017/12/08 - 2018/02/08
 */

import Urls from '../urls'
import Core from '../core'

export default class Common {
  // 通用服务端存储数据接口
  static setData (data: SApiIF.Common.SetDataParam, opts?: SApiIF.OptsParam): Promise<SApiIF.Common.SetData> {
    if (data.dataFrom === undefined) { data.dataFrom = app.id }
    if (data.expire === undefined) { data.expire = -1 }
    // data = this.paramsProcess(data)
    // let dataAny: any = data
    // if (dataAny.params) { dataAny.params = JSON.stringify(dataAny.params) }
    let generateOpts = Core.assign({
      URL: Urls.SET_DATA,
      data: data,
      keyWord: '保存云数据'
    }, opts)
    return Core.post(generateOpts)
  }
  // 通用服务端读取数据接口
  static getData (data: SApiIF.Common.GetDataParam, opts?: SApiIF.OptsParam): Promise<SApiIF.Common.GetData> {
    // data = this.paramsProcess(data)
    // let dataAny: any = data
    // if (dataAny.params) { dataAny.params = JSON.stringify(dataAny.params) }
    let generateOpts = Core.assign({
      URL: Urls.GET_DATA,
      data: data,
      keyWord: '读取云数据'
    }, opts)
    return Core.get(generateOpts)
  }
  // data.params参数默认处理（此方法会修改传输的data对象）
  // private static paramsProcess<T extends SApiIF.Common.SetDataParam | SApiIF.Common.GetDataParam> (data: T): T {
  //   if (data.params === undefined) { data.params = {} }
  //   // let params = JSON.parse(data.params)
  //   let params = data.params
  //   if (params.appName === undefined) {
  //     // 未设置appName值，使用默认参数
  //     params.appName = app.id
  //   } else if (params.appName === null) {
  //     // 手动不用appName，默认值也不要
  //     delete params.appName
  //   }
  //   if (params.userId === undefined) {
  //     // 未设置userId值，使用默认参数
  //     params.userId = lib.store.state.user.userId
  //   } else if (params.userId === null) {
  //     // 手动不用userId，默认值也不要
  //     delete params.userId
  //   }
  //   // if (Object.keys(params).length) {
  //   //   data.params = JSON.stringify(params)
  //   // } else {
  //   //   delete data.params
  //   // }
  //   if (!Object.keys(params).length) { delete data.params }
  //   return data
  // }
}
