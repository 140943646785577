import 'whatwg-fetch'

export default class Base {
  static debugHosts = [ '192.168.9.9', '192.168.9.22', '192.168.9.112', 'localhost' ]
  static init () {
    app.runEnv = Base.getRunEnv()
  }
  static getRunEnv () {
    if (Base.debugHosts.includes(window.location.hostname)) {
      return 'dev'
    }
    return 'production'
  }
}
Base.init()
