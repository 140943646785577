






































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import jsSHA from 'jssha'

interface PopupVideo2Param {
  url: string
  title?: string
}

@Component({ components: {} })
export default class TrainVideo extends Vue {
  audio: HTMLAudioElement | null = null
  component: HTMLAudioElement | null = null
  staticPath: string = 'https://s.doumiao9.com/official'
  scrollY: number = 0

  lockBoxOpen (boxEle: HTMLDivElement) {
    console.log('lockBoxOpen')
    if (!boxEle) { return }
    let contentEle = boxEle.querySelector('.lock-content')
    let iptWrapEle = boxEle.querySelector('.lock-ipt')
    let iptEle = boxEle.querySelector('.lock-pwd') as HTMLInputElement
    let hash = boxEle.getAttribute('data-lockpwd')
    if (!iptEle || !iptEle.value) { return }
    let shaObj = new jsSHA('SHA-512', 'TEXT')
    shaObj.update(iptEle.value)
    let iptHash = shaObj.getHash('HEX')
    if (iptHash === hash) {
      if (contentEle) { contentEle.classList.remove('lock-hide') }
      if (iptWrapEle) { iptWrapEle.classList.add('lock-hide') }
    } else {
      this.$message.error('密码错误')
    }
  }
  popupVideo2Show (param: PopupVideo2Param) {
    let popupEle = document.querySelector('.popup-video2') as HTMLDivElement
    let videoEle = popupEle.querySelector('.popup-video2-video') as HTMLVideoElement
    let titleEle = popupEle.querySelector('.popup-video2-title') as HTMLDivElement
    if (!popupEle) { return }
    if (!param || !param.url) { return }
    if (videoEle) {
      videoEle.src = param.url
      videoEle.play().then().catch()
    }
    if (titleEle) {
      if (param.title) {
        titleEle.innerText = param.title
        titleEle.classList.remove('hide')
      } else {
        titleEle.classList.add('hide')
      }
    }
    popupEle.classList.add('on')
  }
  videoClose () {
    window.scrollTo(0, this.scrollY)
    let popup = document.querySelector('.product-video-popup') as HTMLDivElement
    popup.style.display = 'none'
    let video = document.querySelector('.product-introduce-video') as HTMLVideoElement
    video.pause()
  }
  popupVideo2Close () {
    let popupEle = document.querySelector('.popup-video2') as HTMLDivElement
    let videoEle = popupEle.querySelector('.popup-video2-video') as HTMLVideoElement
    if (videoEle) { videoEle.pause() }
    if (popupEle) { popupEle.classList.remove('on') }
  }
  // showPanel (name?: string) {
  //   let navItem1 = document.querySelector('.nav-item1')
  //   let navItem2 = document.querySelector('.nav-item2')
  //   // let navItem3 = document.querySelector('.nav-item3')
  //   let panelTrain = document.querySelector('.panel-train')
  //   let panelGuwen = document.querySelector('.panel-guwen')
  //   let show = panelTrain
  //   let btnOn = navItem1
  //   if (name === 'navitem1') {
  //     show = panelTrain
  //     btnOn = navItem1
  //   } else if (name === 'navitem2') {
  //     show = panelGuwen
  //     btnOn = navItem2
  //   }
  //   if (panelTrain && panelTrain !== show) {
  //     if (navItem2) { navItem2.classList.remove('on') }
  //     panelTrain.classList.remove('show')
  //     this.panelVideoPause(panelTrain)
  //   }
  //   if (panelGuwen && panelGuwen !== show) {
  //     if (navItem2) { navItem2.classList.remove('on') }
  //     panelGuwen.classList.remove('show')
  //     this.panelVideoPause(panelGuwen)
  //   }
  //   // if (btnOn) { btnOn.classList.add('video-nav-train') }
  //   // if (show) { show.classList.add('show') }
  // }
  // panelVideoPause (ele: Element) {
  //   let videos = ele.querySelectorAll('.chain-introduce-video')
  //   let len = videos.length
  //   for (let i = 0; i < len; i++) {
  //     let video = videos[i] as HTMLVideoElement
  //     video.pause()
  //   }
  // }
  mounted () {
    let btnLockConfirmList = document.querySelectorAll('.lock-confirm')
    let btnLockPasswordList = document.querySelectorAll('.lock-pwd') /* as NodeListOf<HTMLInputElement> */
    let btnVideoBoxList = document.querySelectorAll('.video-box')
    let btnPopupVideo2Close = document.querySelector('.popup-video2-close')
    let btnClose = document.querySelector('.video-popup-close-box')
    let navItem1 = document.querySelector('.nav-item1')
    let navItem2 = document.querySelector('.nav-item2')
    // let navItem3 = document.querySelector('.nav-item3')
    let panelTrain = document.querySelector('.panel-train')
    let panelGuwen = document.querySelector('.panel-guwen')
    let btnMayPrimary = document.querySelector('.shixun51-primary')
    let btnMayAdvanced = document.querySelector('.shixun51-advanced')
    let btnMayAssess = document.querySelector('.shixun51-assess')
    if (btnLockConfirmList) {
      btnLockConfirmList.forEach(btn => {
        btn.addEventListener('click', (event) => {
          event.preventDefault()
          let aEle = event.currentTarget as HTMLAnchorElement
          let boxEle: HTMLDivElement | null = null
          let contentEle = null
          let iptEle = null
          if (aEle) { boxEle = aEle.closest('.lock-box') as HTMLDivElement }
          if (boxEle) {
            this.lockBoxOpen(boxEle)
          }
        })
      })
    }
    if (btnLockPasswordList) {
      btnLockPasswordList.forEach(ipt => {
        ipt.addEventListener('keydown', (event: any) => {
          if (event.key === 'Enter') {
            let boxEle = ipt.closest('.lock-box') as HTMLDivElement
            if (boxEle) { this.lockBoxOpen(boxEle) }
          }
        })
      })
    }
    if (btnVideoBoxList) {
      btnVideoBoxList.forEach((videoDiv, index) => {
        let url = videoDiv.getAttribute('data-video-url')
        if (!url || url === '') { return }
        url = this.staticPath + url
        let title = undefined
        let titleELe = videoDiv.querySelector('.video-box-title')
        if (titleELe) { title = titleELe.innerHTML }
        let param: PopupVideo2Param = {
          url,
          title
        }
        videoDiv.addEventListener('click', (event) => {
          this.popupVideo2Show(param)
        })
      })
    }
    if (btnClose) {
      btnClose.addEventListener('click', () => {
        this.videoClose()
        if (sessionStorage.clickcount) {
          // 判断是否是用户主动关闭声音，是就不播放
          if (this.audio && sessionStorage.appMusicState === 'false') {
            this.audio.play().catch()
            this.audio.currentTime = sessionStorage.clickcount
            if (this.component)this.component.classList.remove('paused')
          }
        }
      })
    }
    if (btnPopupVideo2Close) {
      btnPopupVideo2Close.addEventListener('click', () => {
        this.popupVideo2Close()
      })
    }
    if (navItem1) {
      navItem1.addEventListener('click', () => {
        // this.showPanel('navitem1')
        navItem1!.classList.add('video-nav-train')
        navItem2!.classList.remove('video-nav-train')
        panelTrain!.classList.add('show')
        panelGuwen!.classList.remove('show')
      })
    }
    if (navItem2) {
      navItem2.addEventListener('click', () => {
        // this.showPanel('navitem2')
        navItem2!.classList.add('video-nav-train')
        navItem1!.classList.remove('video-nav-train')
        panelTrain!.classList.remove('show')
        panelGuwen!.classList.add('show')
      })
    }
    // 五一师训
    let mayPrimary = document.querySelector('.primary-51')
    let mayadvanced = document.querySelector('.advanced-51')
    let mayAssess = document.querySelector('.assess-51')
    if (btnMayPrimary) {
      btnMayPrimary.addEventListener('click', () => {
        mayPrimary!.classList.remove('hide')
        mayadvanced!.classList.remove('show')
        mayAssess!.classList.remove('show')
        btnMayPrimary!.classList.remove('leave')
        btnMayAdvanced!.classList.remove('on')
        btnMayAssess!.classList.remove('on')
      })
    }
    if (btnMayAdvanced) {
      btnMayAdvanced.addEventListener('click', () => {
        mayadvanced!.classList.add('show')
        mayPrimary!.classList.add('hide')
        mayAssess!.classList.remove('show')
        btnMayPrimary!.classList.add('leave')
        btnMayAdvanced!.classList.add('on')
        btnMayAssess!.classList.remove('on')
      })
    }
    if (btnMayAssess) {
      btnMayAssess.addEventListener('click', () => {
        mayAssess!.classList.add('show')
        mayPrimary!.classList.add('hide')
        mayadvanced!.classList.remove('show')
        btnMayPrimary!.classList.add('leave')
        btnMayAdvanced!.classList.remove('on')
        btnMayAssess!.classList.add('on')
      })
    }
  }
}
