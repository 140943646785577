








































































import { Component, Vue, Inject } from 'vue-property-decorator'
import Advert from '@/components/Advert/Advert.vue'
import Advert2 from '@/components/Advert/Advert2.vue'
import Advert3 from '@/components/Advert/Advert3.vue'
import Advert4 from '@/components/Advert/Advert4.vue'
import Advert5 from '@/components/Advert/Advert5.vue'
import Advert6 from '@/components/Advert/Advert6.vue'
import SideNav from '@/components/SideNav/sideNav.vue'

@Component({ components: { Advert, Advert2, Advert3, Advert4, Advert5, SideNav, Advert6 } })
export default class Voice extends Vue {
  loaded: boolean = false // 页面是否已经加载完成
  to (path: string) {
    Lib.router.push(path)
  }
  back () {
    Lib.router.go(-1)
  }
  download (path: string) {
    window.open(path)
  }
  created () {
    this.loaded = true
  }
  mounted () {
    Lib.dispatchEvent(document, 'custom-render-trigger')
    // side-nav-item1、side-nav-item-active在sideNav.vue组件中声明
    let sideNav = document.querySelector('.side-nav-item3')
    let sideNavRoute = document.querySelector('#side-nav')// 选中AppHead.vue中'联系我们'
    if (sideNavRoute) {
      sideNavRoute.classList.remove('on')
      sideNavRoute.classList.add('on')
    }
    if (sideNav) {
      sideNav.classList.remove('side-nav-item-active')
      sideNav.classList.add('side-nav-item-active')
    }
  }
}
