






























































import { Component, Vue, Inject } from 'vue-property-decorator'

@Component
export default class NewsIndex extends Vue {
  loaded: boolean = false // 页面是否已经加载完成
  to (path: string) {
    Lib.router.push(path)
  }
  back () {
    Lib.router.go(-1)
  }
  download (path: string) {
    window.open(path)
  }
  created () {
    this.loaded = true
  }
  mounted () { Lib.dispatchEvent(document, 'custom-render-trigger') }
}
