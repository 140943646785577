import { render, staticRenderFns } from "./AppMusicPlayer.vue?vue&type=template&id=6332de32&scoped=true&"
import script from "./AppMusicPlayer.vue?vue&type=script&lang=ts&"
export * from "./AppMusicPlayer.vue?vue&type=script&lang=ts&"
import style0 from "./AppMusicPlayer.vue?vue&type=style&index=0&id=6332de32&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6332de32",
  null
  
)

component.options.__file = "AppMusicPlayer.vue"
export default component.exports