import { render, staticRenderFns } from "./Titbits.vue?vue&type=template&id=684a47ce&scoped=true&"
import script from "./Titbits.vue?vue&type=script&lang=ts&"
export * from "./Titbits.vue?vue&type=script&lang=ts&"
import style0 from "./Titbits.vue?vue&type=style&index=0&id=684a47ce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "684a47ce",
  null
  
)

component.options.__file = "Titbits.vue"
export default component.exports