





























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class RecruitedItem extends Vue {
  @Prop() data?: object  // 数据
  isShow: boolean = true
  hideContent () {
    this.isShow = !this.isShow
  }
}
