/**
 * SAPI，数据请求交互层，核心请求部分
 * @version 1.0.1
 * @author JQ<jianqingchina@qq.com>
 * 2017/11/17 - 2018/02/08
 */
import Lib from '@/plugins/lib'
import fetchJsonp from 'fetch-jsonp'
// import URLS from './urls'
// import axios from 'axios'

interface OptsParam {
  URL: string
  data: any
  // silent: boolean
  keyWord: string
  mode: RequestMode
  credentials: RequestCredentials
  result: SApiIF.OptsParamResult
}

export default class Core {
  static errIsLogout: number = -1 // 未登录状态码，默认是 -1
  static logoutHandler () {
    let query: any = {}
    // tslint:disable-next-line
    if (Lib.router.original.currentRoute.query.redirect !== undefined) {
      query.redirect = Lib.router.original.currentRoute.query.redirect
    } else {
      query.redirect = Lib.router.original.currentRoute.fullPath
    }
    Lib.router.replace({ path: '/login/', query })
  }
  /**
   * 合并fetch参数，并自动生成跨域参数及token
   * @param  {Object} opts1 默认参数设置
   * @param  {Object} opts2 手动指定参数设置
   * @return {Object}       生成合并处理后的参数设置结果
   */
  static assign (opts1: any, opts2?: SApiIF.OptsParam): OptsParam {
    let opts = Object.assign(opts1, opts2)
    if (opts.mode === undefined) {
      // 没有指定模式时，自动生成模式。
      // 如果请求等于与当前域名相同，则生成默认值，不跨域（no-cors），否则生成值进行跨域（cors）
      if (opts.URL.startsWith(window.location.protocol + '//' + window.location.host)) {
        opts.mode = 'no-cors'
      } else {
        opts.mode = 'cors'
        // 跨域，显示带token
        if (Lib.store.state.user.token) {
          if (opts.data === undefined) { opts.data = {} }
          opts.data.token = Lib.store.state.user.token
        }
      }
    }
    if (opts.credentials === undefined) { opts.credentials = 'same-origin' }
    log.debug('[core.assign] opts:', opts)
    return opts
  }

  /**
   * 核心公用post请求
   * @param  {object} opts             参数对象
   * @param  {string} opts.URL         要请求的地址（完整路径，接收后不进行组装）
   * @param  {object} opts.data        要提交的参数，object形式，内部会对参数进行encode操作
   * @param  {string} opts.keyWord     用户提示、日志提示关键字
   * @param  {object} [opts.result]   （可选）需要用到的服务器返回数据key，key索引、下标, default 默认值
   * @return {Promise}                 返回一个请求结果Promise
   */
  static post<T> (opts: SApiIF.OptsParam): Promise<T> {
    log.debug('[Sapi core.post] opts:', opts)
    return new Promise((resolve, reject) => {
      fetch(opts.URL, {
        method: 'POST',
        mode: opts.mode,
        credentials: opts.credentials,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        body: Lib.Url.encoded(opts.data)
      }).then((res) => {
        res.json().then((data) => {
          if (data.err === this.errIsLogout) {
            this.logoutHandler()
            reject(data.msg)
            return false
          } else if (data.err !== 0) {
            log.debug(data.msg)
            reject(data.msg)
            // if (!opts.silent) {
            //   Lib.alert(data.msg)
            // }
            return false
          }
          if (opts.result && opts.result.key) {
            let result = data[opts.result.key]
            if (data[opts.result.key] === undefined || data[opts.result.key] === null) { result = opts.result.default }
            return resolve(result)
          }
          resolve(data)
        }).catch(err => {
          log.debug(err)
          reject(err)
          // if (!opts.silent) { Lib.alert('数据解析错误，' + opts.keyWord + '失败，请稍后重试或联系管理员') }
        })
      }).catch((ex) => {
        log.debug(ex)
        reject(ex)
        // reject(ex.message)
        // if (!opts.silent) { Lib.alert('网络错误，' + opts.keyWord + '失败，请稍后重试或联系管理员') }
      })
    })
  }

  /**
   * 核心公用jsonp请求
   * @param  {object} opts             参数对象
   * @param  {string} opts.URL         要请求的地址（完整路径，接收后不进行组装）
   * @param  {object} opts.data        要提交的参数，object形式，内部会对参数进行encode操作
   * @param  {string} opts.keyWord     用户提示、日志提示关键字
   * @param  {object} [opts.result]   （可选）需要用到的服务器返回数据key，key索引、下标, default 默认值
   * @return {Promise}                 返回一个请求结果Promise
   */
  static jsonp<T> (opts: SApiIF.OptsParam): Promise<T> {
    log.debug('[Sapi core.jsonp] opts:', opts)
    return new Promise((resolve, reject) => {
      fetchJsonp(Lib.Url.stringify(opts.URL, opts.data), {
        jsonpCallback: 'jsoncallback',
        timeout: 20000
      }).then((res) => {
        res.json().then((data) => {
          if (data.err === this.errIsLogout) {
            this.logoutHandler()
            reject(data.msg)
            return false
          } else if (data.err !== 0) {
            log.debug(data.msg)
            reject(data.msg)
            // if (!opts.silent) {
            //   Lib.alert(data.msg)
            // }
            return false
          }
          if (opts.result && opts.result.key) {
            let result = data[opts.result.key]
            if (data[opts.result.key] === undefined || data[opts.result.key] === null) { result = opts.result.default }
            return resolve(result)
          }
          resolve(data)
        }).catch(err => {
          log.debug(err)
          reject(err)
          // if (!opts.silent) { Lib.alert('数据解析错误，' + opts.keyWord + '失败，请稍后重试或联系管理员') }
        })
      }).catch((ex) => {
        log.debug(ex)
        reject('网络错误，' + opts.keyWord + '失败，请确保网络正常后重试或联系管理员')
        // reject(ex.message)
        // if (!opts.silent) {
        //   Lib.alert('网络错误，' + opts.keyWord + '失败，请稍后重试或联系管理员')
        // }
      })
    })
  }

  /**
   * 核心公用get请求
   * @param  {object} opts             参数对象
   * @param  {string} opts.URL         要请求的地址（完整路径，接收后不进行组装）
   * @param  {object} opts.data        要提交的参数，object形式，内部会对参数进行encode操作
   * @param  {string} opts.keyWord     用户提示、日志提示关键字
   * @param  {object} [opts.result]   （可选）需要用到的服务器返回数据key，key索引、下标, default 默认值
   * @return {Promise}                 返回一个请求结果Promise
   */
  static get<T> (opts: OptsParam): Promise<T> {
    return new Promise((resolve, reject) => {
      fetch(Lib.Url.stringify(opts.URL, opts.data), {
        method: 'GET',
        mode: opts.mode,
        credentials: opts.credentials,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      }).then((res) => {
        res.json().then((data) => {
          if (data.err === this.errIsLogout) {
            this.logoutHandler()
            reject(data.msg)
            return false
          } else if (data.err !== 0) {
            log.debug(data.msg)
            reject(data.msg)
            // if (!opts.silent) {
            //   Lib.alert(data.msg)
            // }
            return false
          }
          if (opts.result && opts.result.key) {
            let result = data[opts.result.key]
            if (data[opts.result.key] === undefined || data[opts.result.key] === null) { result = opts.result.default }
            return resolve(result)
          }
          resolve(data)
        }).catch(err => {
          log.debug(err)
          reject(err)
          // if (!opts.silent) { Lib.alert('数据解析错误，' + opts.keyWord + '失败，请稍后重试或联系管理员') }
        })
      }).catch((ex) => {
        log.debug(ex)
        reject(ex)
        // reject(ex.message)
        // if (!opts.silent) {
        //   Lib.alert('网络错误，' + opts.keyWord + '失败，请稍后重试或联系管理员')
        // }
      })
    })
  }
}
/**
 * 上传文件接口
 * @param  {object} opts             参数对象
 * @param  {string} opts.url         要请求的地址（接收到此参数后进行组装生成完成路径）
 * @param  {string} opts.fullUrl     要请求的地址（完整路径，接收后不进行组装）
 * @param  {object} opts.data        要提交的参数
 * @param  {string} opts.keyWord     用户提示、日志提示关键字
 * @param  {object} [opts.result]   （可选）需要用到的服务器返回数据key，key索引、下标, default 默认值
 * @return {Promise}                 返回一个请求结果Promise
 */
// let corePostFile = (opts) => {
//   let fullUrl = ''
//   if (opts.fullUrl) {
//     fullUrl = opts.fullUrl
//   } else {
//     fullUrl = Lib.url(opts.url)
//   }
//   return new Promise((resolve, reject) => {
//     fetch(fullUrl, {
//       method: 'POST',
//       mode: Lib.fetchMode,
//       body: opts.data
//     }).then((res) => {
//       res.json().then((data) => {
//         if (data.isSuccess) {
//           resolve(data)
//         } else {
//           log.debug(data)
//           Lib.alert(opts.keyWord + '失败，请稍后重试或联系管理员')
//           reject(data.msg)
//         }
//       })
//     }).catch((ex) => {
//       log.debug(ex)
//       reject(ex)
//       Lib.alert('网络错误，' + opts.keyWord + '失败，请稍后重试或联系管理员')
//     })
//   })
// }
