














import { Vue } from 'vue-property-decorator'

export default class SideNav extends Vue {
  mounted () {
    console.log()
  }
}
