















import { Component, Vue, Inject } from 'vue-property-decorator'

@Component
export default class InstallTeacher extends Vue {
  loaded: boolean = false // 页面是否已经加载完成
  showBtnScrolltop: boolean = false // 显示返回顶部按钮
  @Inject() appAuth!: (silent?: boolean) => Promise<{}>
  to (path: string) {
    Lib.router.push(path)
  }
  back () {
    Lib.router.go(-1)
  }
  toAnchor (selector: string) {
    log.debug('[Shansuan toAnchor] selector:', selector)
    let anchor = document.querySelector(selector)
    if (!anchor) { return }
    document.documentElement.scrollTop = (anchor as HTMLElement).offsetTop
    document.body.scrollTop = (anchor as HTMLElement).offsetTop
  }
  scrollTop () {
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
  }
  created () {
    this.loaded = true
  }
  mounted () {
    this.scrollTop()
    // 兼容prerender-spa-plugin
    document.dispatchEvent(new Event('custom-render-trigger'))
    let btnScrollTop = document.querySelector('.btn-scrolltop')
    let btnBack = document.querySelector('.back')
    if (btnScrollTop) {
      btnScrollTop.addEventListener('click', this.scrollTop)
      window.addEventListener('scroll', () => {
        if (document.documentElement.scrollTop >= 200 || document.body.scrollTop >= 200) {
          this.showBtnScrolltop = true
          btnScrollTop!.classList.add('btn-scrolltop--show')
        } else {
          this.showBtnScrolltop = false
          btnScrollTop!.classList.remove('btn-scrolltop--show')
        }
      })
    }
    if (btnBack) {
      btnBack.addEventListener('click', this.back)
    }
  }
}
