import './base' // 更新需要用到的基层依赖，改动将影响更新模块，改动后需要严谨测试（特别是各个不同机型、APP版本的兼容问题）
import './plugins/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Lib from './plugins/lib'
import SApi from './sapi'
import VueTouch from 'vue-touch'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/sideNav.css'

// 带参数非hash类型url转换成hash类型url
console.log('[main history.pushState] run.')
let searchIndex = window.location.href.indexOf('?')
let hashIndex = window.location.href.indexOf('#')
if (searchIndex !== -1 && searchIndex < hashIndex) {
  console.log('[main history.pushState] window.location.href:', window.location.href)
  let searchQuery = window.location.href.slice(searchIndex + 1, hashIndex)
  let hashParam = window.location.href.slice(hashIndex + 1)
  if (hashParam.includes('?')) {
    hashParam = hashParam + '&' + searchQuery
  } else {
    hashParam = hashParam + '?' + searchQuery
  }
  let href = window.location.href.slice(0, searchIndex) + '#' + hashParam
  console.log('[main history.pushState] href:', href)
  if (window.history.pushState) {
    window.history.pushState({}, document.title, href)
  } else {
    // Android 4.1 不支持 history.pushState
    app.aborted = true
    window.location.replace(href)
  }
}

Vue.config.productionTip = false
Vue.use(VueTouch, { name: 'v-touch' })
Vue.use(ElementUI)
// @ts-ignore

let appId = process.env.VUE_APP_ID
if (appId) { app.id = appId }

Lib.store = store
Lib.router.original = router
window.SApi = SApi
window.Lib = Lib
window.log = Lib.log

// 检测登录
let token = Lib.Url.query('t')
if (token && !Lib.store.state.user.token) {
  Lib.store.commit('user/set', { token })
  Lib.store.dispatch('user/check')
}

Lib.vue = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#appELe')

// tslint:disable-next-line
// tslint:disable-next-line
document.body.addEventListener('touchstart', () => {}) // 修iOS :active无效问题
