
















































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Advert4 from '@/components/Advert/Advert4.vue'
import jsSHA from 'jssha'
import SideNav from '@/components/SideNav/sideNav.vue'
@Component({ components: { Advert4 } })
export default class Cube extends Vue {
  loaded: boolean = true // 页面是否已经加载完成
  validated: boolean = false // 通过验证的
  passport: string = ''
  downloadStatus = {
    shansuan: false,
    teacher: false,
    siwei: false,
    JinJuTeacher: false
  }
//   展示了解详情页面
  showBook (name: string) {
    let learn = document.querySelector('.learn.book')
    if (!learn) { return }
    learn.classList.remove('middle','senior','primary')
    learn.classList.add('show', name)
  }
  //   展示视频教程页面
  showVideo () {
    let learn = document.querySelector('.learn.video')
    if (!learn) { return }
    learn.classList.add('show')
  }
  //   关闭了解详情页面
  noShowLearn () {
    let learn = document.querySelector('.book')
    if (!learn) { return }
    learn.classList.remove('show')
    console.log('learn')
  }
   //   关闭视频教程页面
  noShowVideo () {
    let video = document.querySelector('.video')
    if (!video) { return }
    video.classList.remove('show')
    console.log('video')
  }
  hideDownload (appName: string) {
    if (appName === 'shansuan') {
      this.downloadStatus.shansuan = false
    } else if (appName === 'teacher') {
      this.downloadStatus.teacher = false
    } else if (appName === 'siwei') {
      this.downloadStatus.siwei = false
    } else if (appName === 'JinJuTeacher') {
      this.downloadStatus.JinJuTeacher = false
    }
  }
  showDownload (appName: string) {
    if (appName === 'shansuan') {
      this.downloadStatus.shansuan = true
    } else if (appName === 'teacher') {
      this.downloadStatus.teacher = true
    } else if (appName === 'siwei') {
      this.downloadStatus.siwei = true
    } else if (appName === 'JinJuTeacher') {
      this.downloadStatus.JinJuTeacher = true
    }
  }
  removeSideNav () {
    let sideNav1 = document.querySelector('.side-nav-item1')
    let sideNav2 = document.querySelector('.side-nav-item2')
    let sideNav3 = document.querySelector('.side-nav-item3')
    let sideNav4 = document.querySelector('.side-nav-item4')
    let otherQzmf = document.querySelector('.other-qzmf')
    let otherjinjuDownload = document.querySelector('.other-jinju-download')
    let otherdmDownload = document.querySelector('.other-dm-download')
    let otherEnroll = document.querySelector('.other-enroll')
    sideNav1!.classList.remove('side-nav-item-active')
    sideNav2!.classList.remove('side-nav-item-active')
    sideNav3!.classList.remove('side-nav-item-active')
    sideNav4!.classList.remove('side-nav-item-active')
    otherQzmf!.classList.add('content-hide')
    otherjinjuDownload!.classList.add('content-hide')
    otherdmDownload!.classList.add('content-hide')
    otherEnroll!.classList.add('content-hide')
  }
  qzmfPage () {
    let sideNav1 = document.querySelector('.side-nav-item1')
    let otherQzmf = document.querySelector('.other-qzmf')
    if (sideNav1 && otherQzmf) {
      this.removeSideNav()
      sideNav1.classList.add('side-nav-item-active')
      otherQzmf.classList.remove('content-hide')
    }
    this.$router.push({ query: { page: 'mofang' } })
  }
  jinjuDownloadPage () {
    let sideNav2 = document.querySelector('.side-nav-item2')
    let otherjinjuDownload = document.querySelector('.other-jinju-download')
    if (sideNav2 && otherjinjuDownload) {
      this.removeSideNav()
      sideNav2.classList.add('side-nav-item-active')
      otherjinjuDownload.classList.remove('content-hide')
    }
    this.$router.push({ query: { page: 'jinju' } })
  }
  dmDownloadPage () {
    let sideNav3 = document.querySelector('.side-nav-item3')
    let otherdmDownload = document.querySelector('.other-dm-download')
    if (sideNav3 && otherdmDownload) {
      this.removeSideNav()
      sideNav3.classList.add('side-nav-item-active')
      otherdmDownload.classList.remove('content-hide')
    }
    this.$router.push({ query: { page: 'doumiao' } })
  }
  enroll () {
    let sideNav4 = document.querySelector('.side-nav-item4')
    let otherEnroll = document.querySelector('.other-enroll')
    if (sideNav4 && otherEnroll) {
      this.removeSideNav()
      sideNav4.classList.add('side-nav-item-active')
      otherEnroll.classList.remove('content-hide')
    }
    this.$router.push({ query: { page: 'zhaomanduo' } })
  }
  confirm (val: string) {
    // console.debug('confirm val:', val)
    let shaObj = new jsSHA('SHA-512', 'TEXT')
    shaObj.update(val)
    let hash = shaObj.getHash('HEX')
    // let intro = document.querySelector('.chain-introduce')
    let sideNav3 = document.querySelector('.side-nav-item3')
    let passport = document.querySelector('.side-nav-passport')
    if (hash === 'f34d41890d7c3652d84a12c1130680bb84f0661333ad8ae16ea4af5b16f2698b4407ce6fab7892f80895a7865c8b2914abd88bfd7735b94f7f53be16604f2e80') {
      this.validated = true
      if (sideNav3) {
        sideNav3.classList.remove('side-nav-vip')
      }
      if (passport) { passport.classList.add('side-nav-vip') }
    } else {
      this.$message.error('密码错误，请重新输入！')
    }
  }
  mounted () {
    Lib.dispatchEvent(document, 'custom-render-trigger')
    let sideNav1 = document.querySelector('.side-nav-item1')
    let sideNav2 = document.querySelector('.side-nav-item2')
    let sideNav3 = document.querySelector('.side-nav-item3')
    let sideNav4 = document.querySelector('.side-nav-item4')
    let btn1 = document.querySelectorAll('.btn1')
    // let primaryBook = document.querySelector('.primary-book')
    // let middleBook = document.querySelector('.middle-book')
    // let seniorBook = document.querySelector('.senior-book')
    let btn2 = document.querySelectorAll('.bottom-text')
    let btn3 = document.querySelectorAll('.btn2')
    let learnClose = document.querySelectorAll('.book-close')
    let learnClose2 = document.querySelector('.video-close')
    if (sideNav1) {
      sideNav1.addEventListener('click', (event) => {
        event.preventDefault()
        this.qzmfPage()
      })
    }
    if (sideNav2) {
      sideNav2.addEventListener('click', (event) => {
        event.preventDefault()
        this.jinjuDownloadPage()
      })
    }
    if (sideNav3) {
      sideNav3.addEventListener('click', (event) => {
        event.preventDefault()
        this.dmDownloadPage()
      })
    }
    if (sideNav4) {
      sideNav4.classList.remove('side-nav-item-active')
      sideNav4.classList.add('side-nav-item-active')
      sideNav4.addEventListener('click', (event) => {
        event.preventDefault()
        this.enroll()
      })
    }
    if (btn1) {
      btn1.forEach(btn => {
        btn.addEventListener('click', event => {
          event.preventDefault()
          let btn = event.currentTarget as HTMLDivElement
          if (btn.classList.contains('middle-book')) {
            this.showBook('middle')
          } else if (btn.classList.contains('senior-book')) {
            this.showBook('senior')
          } else {
            this.showBook('primary')
          }
        })
      })
    }
    if (btn2) {
      btn2.forEach(btn => {
        btn.addEventListener('click', event => {
          event.preventDefault()
          this.showVideo()
        })
      })
    }
    if (btn3) {
      btn3.forEach(btn => {
        btn.addEventListener('click', event => {
          event.preventDefault()
          window.open('https://doumiao9.com/mofang/')
        })
      })
    }
    if (learnClose) {
      learnClose.forEach(close => {
        close.addEventListener('click', event => {
          event.preventDefault()
          this.noShowLearn()
        })
      })
    }
    if (learnClose2) {
      learnClose2.addEventListener('click', event => {
        event.preventDefault()
        this.noShowVideo()
      })
    }
    // 金桔思维下载
    let downloadSiwei = document.querySelector('.product-item-download-siwei')
    let btnCloseSiwei = document.querySelector('.product-item-close-siwei')
    let btnDownloadSiwei = document.querySelector('.product-btn-download-siwei')
    let downloadjinjuTeacher = document.querySelector('.product-item-download-jinjuTeacher')
    let btnClosejinjuTeacher = document.querySelector('.product-item-close-jinjuTeacher')
    let btnDownloadjinjuTeacher = document.querySelector('.product-btn-download-jinjuTeacher')
    if (btnDownloadSiwei && downloadSiwei) {
      btnDownloadSiwei.addEventListener('click', () => {
        downloadSiwei!.classList.add('show')
      })
    }
    if (btnCloseSiwei && downloadSiwei) {
      btnCloseSiwei.addEventListener('click', () => {
        downloadSiwei!.classList.remove('show')
      })
    }
    if (btnCloseSiwei && downloadSiwei) {
      btnCloseSiwei.addEventListener('click', () => {
        downloadSiwei!.classList.remove('show')
      })
    }
    if (btnDownloadjinjuTeacher && downloadjinjuTeacher) {
      btnDownloadjinjuTeacher.addEventListener('click', () => {
        downloadjinjuTeacher!.classList.add('show')
      })
    }
    if (btnClosejinjuTeacher && downloadjinjuTeacher) {
      btnClosejinjuTeacher.addEventListener('click', () => {
        downloadjinjuTeacher!.classList.remove('show')
      })
    }
    if (btnClosejinjuTeacher && downloadjinjuTeacher) {
      btnClosejinjuTeacher.addEventListener('click', () => {
        downloadjinjuTeacher!.classList.remove('show')
      })
    }
    let btnConfirm = document.querySelector('.chain-btn-confirm')
    let inputPassport: HTMLInputElement | null = document.querySelector('.chain-input-passport .el-input__inner')
    if (btnConfirm) {
      btnConfirm.addEventListener('click', () => {
        let val = ''
        if (inputPassport) { val = inputPassport.value }
        this.confirm(val)
      })
    }
    if (inputPassport) {
      inputPassport.addEventListener('keyup', (event: KeyboardEvent) => {
        let isEnter = false
        if (event.key === 'Enter') {
          isEnter = true
        } else if (event.key === '13') {
          isEnter = true
        }
        if (isEnter) {
          let val = ''
          if (inputPassport) { val = inputPassport.value }
          this.confirm(val)
        }
      })
    }
    if (this.$route.query.page) {
      this.$route.query.page === 'jinju' && (this.jinjuDownloadPage())
      this.$route.query.page === 'mofang' && (this.qzmfPage())
      this.$route.query.page === 'doumiao' && (this.dmDownloadPage())
      this.$route.query.page === 'zhaomanduo' && (this.enroll())
    }
  }
}
