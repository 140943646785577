


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Inject } from 'vue-property-decorator'
import jsSHA from 'jssha'
import advert from '@/components/Advert/Advert.vue'
import videoBox from '@/components/VideoBox/videoBox.vue'
import AppMusicPlayer from '@/components/AppMusicPlayer/AppMusicPlayer.vue'
import Advert7 from '@/components/Advert/Advert7.vue'
interface PopupVideo2Param {
  url: string
  title?: string
}

@Component({ components: { advert, videoBox, Advert7 } })
export default class Chain extends Vue {

  audio: HTMLAudioElement | null = null
  component: HTMLAudioElement | null = null
  loaded: boolean = false // 页面是否已经加载完成
  passport: string = ''
  videoLink: string = '' // 视频链接
  validated: boolean = false // 通过验证的
  scrollY: number = 0
  videoName: string = ''
  staticPath: string = 'https://s.doumiao9.com/official'
  downloadStatus = {
    shansuan: false,
    teacher: false,
    siwei: false
  }
  to (path: string) {
    Lib.router.push(path)
  }
  hideDownload (appName: string) {
    if (appName === 'shansuan') {
      this.downloadStatus.shansuan = false
    } else if (appName === 'teacher') {
      this.downloadStatus.teacher = false
    } else if (appName === 'siwei') {
      this.downloadStatus.siwei = false
    }
  }
  showDownload (appName: string) {
    if (appName === 'shansuan') {
      this.downloadStatus.shansuan = true
    } else if (appName === 'teacher') {
      this.downloadStatus.teacher = true
    } else if (appName === 'siwei') {
      this.downloadStatus.siwei = true
    }
  }
  /* showvideo (num: number) {
    // this.showVideo = num
    let videos: any = document.querySelectorAll('.video')
    let imgs: any = document.querySelectorAll('.chain-introduce-img')
    for (let i = 0; i < imgs.length; i++) {
      imgs[i].classList.remove('none')
    }
    let img: any = document.querySelector('.img' + num.toString())
    img.classList.add('none')
    let video = document.querySelector('#videoBox' + num.toString())
    console.log('num.toString()', num.toString())
    console.log(video)
    if (video) {
      let ele = document.createElement('video')
      // insertAdjacentHTML
      ele.setAttribute('class', 'chain-introduce-video')
      ele.setAttribute('src', this.staticPath + '/media/chain-10-2019112001.mp4')
      ele.setAttribute('controls', 'true')
      ele.setAttribute('autoplay', 'true')
      // let ele = document.createElement('<video class="chain-introduce-video" controls preload="metadata" src="' + this.staticPath  + '"/media/chain-10-2019112001.mp4"></video>')
      video.appendChild(ele)
    }
  } */
  back () {
    Lib.router.go(-1)
  }
  download (path: string) {
    window.open(path)
  }
  created () {
    this.loaded = true
  }
  videoPlay (link: string) {
    this.scrollY = window.scrollY
    window.scrollTo(0,0)
    let popup = document.querySelector('.product-video-popup') as HTMLDivElement
    let video = document.querySelector('.product-introduce-video') as HTMLVideoElement
    let text = document.querySelector('.product-video-jieshao') as HTMLDivElement
    if (link.indexOf('ketang') !== -1) {
      console.log('link' ,link.split('.')[0].split('/')[2].split('-')[2] + '-' + link.split('.')[0].split('/')[2].split('-')[3])
      let type: string = link.split('.')[0].split('/')[2].split('-')[2] + '-' + link.split('.')[0].split('/')[2].split('-')[3]
      this.audio = document.querySelector('.app-music-player-audio')
      this.component = document.querySelector('.component-app-music-player')
      if (this.audio) {
        // 获取当前用户是否主动停止播放
        if (this.audio.paused) {
          sessionStorage.appMusicState = 'true'
        } else {
          sessionStorage.appMusicState = 'false'
        }
        // 设置当前的播放的的时间
        sessionStorage.clickcount = Number(this.audio.currentTime)
        // 暂停
        this.audio.pause()
        if (this.component) {
          this.component.classList.add('paused')
        }
      }
    }
    video.src = this.staticPath + link
    // console.log(video)
    popup.style.display = 'block'
    setTimeout(() => {
      video.width = popup.offsetWidth / 2
      text.style.width = popup.offsetWidth / 2 + 'px'
      return video.play()
    },200)
  }
  videoClose () {
    window.scrollTo(0, this.scrollY)
    let popup = document.querySelector('.product-video-popup') as HTMLDivElement
    popup.style.display = 'none'
    let video = document.querySelector('.product-introduce-video') as HTMLVideoElement
    video.pause()
  }
  confirm (val: string) {
    // console.debug('confirm val:', val)
    let shaObj = new jsSHA('SHA-512', 'TEXT')
    shaObj.update(val)
    let hash = shaObj.getHash('HEX')
    // let intro = document.querySelector('.chain-introduce')
    let chain = document.querySelectorAll('.chain-nav')
    let passport = document.querySelector('.passport')
    if (hash === 'f4fe2e30ad698a6073788d1cc1dd52acc7ab486ba81ae3a14d327d85b6e0bd534cb620ace48986c2e9e558f0502dc0bfd78b05f4ac46b17fa38c5c899f4e3552') {
      this.validated = true
      if (chain) {
        chain.forEach(c => {
          c.classList.add('show')
        })
      }
      if (passport) { passport.classList.add('hide') }
    } else {
      this.$message.error('密码错误，请重新输入！')
      if (chain) {
        chain.forEach(c => {
          c.classList.remove('show')
        })
      }
      if (passport) { passport.classList.remove('hide') }
    }
  }
  showPanel (name?: string) {
    if (name === undefined) { name = 'dmqzxs' }
    let btnNavCourse = document.querySelector('.chain-nav-course')
    let btnNavMofang = document.querySelector('.chain-nav-mofang')
    let btnNavExperience = document.querySelector('.chain-nav-experience')
    let btnNavOther = document.querySelector('.chain-nav-other')
    let btnNavSong = document.querySelector('.chain-nav-song')
    let btnNavHomework = document.querySelector('.chain-nav-homework')
    let btnNavPrice = document.querySelector('.chain-nav-price')
    let btnNavStandard = document.querySelector('.chain-nav-standard')
    let panelCourse = document.querySelector('.panel-course')
    let panelMofang = document.querySelector('.panel-mofang')
    let panelExperience = document.querySelector('.panel-experience')
    let panelOther = document.querySelector('.panel-other')
    let panelSong = document.querySelector('.panel-song')
    let panelHomework = document.querySelector('.panel-homework')
    let panelPrice = document.querySelector('.panel-price')
    let panelStandard = document.querySelector('.panel-standard')
    let btnNavDownload = document.querySelector('.chain-nav-download')
    let panelDownload = document.querySelector('.panel-download')
    // let btnNavDmqzxs = document.querySelector('.product-nav-ketang')
    let btnNavDmqzxs = document.querySelector('.chain-nav-dmqzxs')
    let btnNavManual = document.querySelector('.chain-nav-manual')
    let btnNavSchool = document.querySelector('.chain-nav-school')
    let panelSchool = document.querySelector('.panel-school')
    let panelDmqzxs = document.querySelector('.panel-dmqzxs')
    let panelManual = document.querySelector('.panel-manual')
    let btnNavProperty = document.querySelector('.chain-nav-property')
    let panelProperty = document.querySelector('.panel-property')
    let btnNavChain = document.querySelector('.chain-nav-chain')
    let panelChain = document.querySelector('.panel-chain')
    let btnNavTaobao = document.querySelector('.chain-nav-taobao')
    let panelTaobao = document.querySelector('.panel-taobao')
    let btnNavTrain = document.querySelector('.chain-nav-train')
    let panelTrain = document.querySelector('.panel-train')
    let btnNavGuwen = document.querySelector('.chain-nav-guwen')
    let panelGuwen = document.querySelector('.panel-guwen')
    let show = btnNavDmqzxs
    let btnOn = btnNavDmqzxs
    if (name === 'course') {
      show = panelCourse
      btnOn = btnNavCourse
    } else if (name === 'experience') {
      show = panelExperience
      btnOn = btnNavExperience
    } else if (name === 'other') {
      show = panelOther
      btnOn = btnNavOther
    } else if (name === 'song') {
      show = panelSong
      btnOn = btnNavSong
    } else if (name === 'homework') {
      show = panelHomework
      btnOn = btnNavHomework
    } else if (name === 'price') {
      show = panelPrice
      btnOn = btnNavPrice
    } else if (name === 'download') {
      show = panelDownload
      btnOn = btnNavDownload
    } else if (name === 'school') {
      show = panelSchool
      btnOn = btnNavSchool
    } else if (name === 'dmqzxs') {
      show = panelDmqzxs
      btnOn = btnNavDmqzxs
    } else if (name === 'manual') {
      show = panelManual
      btnOn = btnNavManual
    } else if (name === 'property') {
      show = panelProperty
      btnOn = btnNavProperty
    } else if (name === 'chain') {
      show = panelChain
      btnOn = btnNavChain
    } else if (name === 'taobao') {
      show = panelTaobao
      btnOn = btnNavTaobao
    } else if (name === 'mofang') {
      show = panelMofang
      btnOn = btnNavMofang
    } else if (name === 'train') {
      show = panelTrain
      btnOn = btnNavTrain
    } else if (name === 'guwen') {
      show = panelGuwen
      btnOn = btnNavGuwen
    } else if (name === 'standard') {
      show = panelStandard
      btnOn = btnNavStandard
    }
    if (panelCourse && panelCourse !== show) {
      if (btnNavCourse) { btnNavCourse.classList.remove('on') }
      panelCourse.classList.remove('show')
      this.panelVideoPause(panelCourse)
    }
    if (panelExperience && panelExperience !== show) {
      if (btnNavExperience) { btnNavExperience.classList.remove('on') }
      panelExperience.classList.remove('show')
      this.panelVideoPause(panelExperience)
    }
    if (panelOther && panelOther !== show) {
      if (btnNavOther) { btnNavOther.classList.remove('on') }
      panelOther.classList.remove('show')
      this.panelVideoPause(panelOther)
    }
    if (panelSong && panelSong !== show) {
      if (btnNavSong) { btnNavSong.classList.remove('on') }
      panelSong.classList.remove('show')
      this.panelVideoPause(panelSong)
    }
    if (panelHomework && panelHomework !== show) {
      if (btnNavHomework) { btnNavHomework.classList.remove('on') }
      panelHomework.classList.remove('show')
      this.panelVideoPause(panelHomework)
    }
    if (panelPrice && panelPrice !== show) {
      if (btnNavPrice) { btnNavPrice.classList.remove('on') }
      panelPrice.classList.remove('show')
      this.panelVideoPause(panelPrice)
    }
    if (panelDownload && panelDownload !== show) {
      if (btnNavDownload) { btnNavDownload.classList.remove('on') }
      panelDownload.classList.remove('show')
      this.panelVideoPause(panelDownload)
    }
    if (panelSchool && panelSchool !== show) {
      if (btnNavSchool) { btnNavSchool.classList.remove('on') }
      panelSchool.classList.remove('show')
      this.panelVideoPause(panelSchool)
    }
    if (panelDmqzxs && panelDmqzxs !== show) {
      if (btnNavDmqzxs) { btnNavDmqzxs.classList.remove('on') }
      panelDmqzxs.classList.remove('show')
      this.panelVideoPause(panelDmqzxs)
    }
    if (panelManual && panelManual !== show) {
      if (btnNavManual) { btnNavManual.classList.remove('on') }
      panelManual.classList.remove('show')
      this.panelVideoPause(panelManual)
    }
    if (panelProperty && panelProperty !== show) {
      if (btnNavProperty) { btnNavProperty.classList.remove('on') }
      panelProperty.classList.remove('show')
      this.panelVideoPause(panelProperty)
    }
    if (panelChain && panelChain !== show) {
      if (btnNavChain) { btnNavChain.classList.remove('on') }
      panelChain.classList.remove('show')
      this.panelVideoPause(panelChain)
    }
    if (panelTaobao && panelTaobao !== show) {
      if (btnNavTaobao) { btnNavTaobao.classList.remove('on') }
      panelTaobao.classList.remove('show')
      this.panelVideoPause(panelTaobao)
    }
    if (panelMofang && panelMofang !== show) {
      if (btnNavMofang) { btnNavMofang.classList.remove('on') }
      panelMofang.classList.remove('show')
      this.panelVideoPause(panelMofang)
    }
    if (panelTrain && panelTrain !== show) {
      if (btnNavTrain) { btnNavTrain.classList.remove('on') }
      panelTrain.classList.remove('show')
      this.panelVideoPause(panelTrain)
    }
    if (panelGuwen && panelGuwen !== show) {
      if (btnNavGuwen) { btnNavGuwen.classList.remove('on') }
      panelGuwen.classList.remove('show')
      this.panelVideoPause(panelGuwen)
    }
    if (panelStandard && panelStandard !== show) {
      if (btnNavStandard) { btnNavStandard.classList.remove('on') }
      panelStandard.classList.remove('show')
      this.panelVideoPause(panelStandard)
    }
    if (btnOn) { btnOn.classList.add('on') }
    if (show) { show.classList.add('show') }
  }
  panelVideoPause (ele: Element) {
    let videos = ele.querySelectorAll('.chain-introduce-video')
    let len = videos.length
    for (let i = 0; i < len; i++) {
      let video = videos[i] as HTMLVideoElement
      video.pause()
    }
  }
  popupVideo2Show (param: PopupVideo2Param) {
    let popupEle = document.querySelector('.popup-video2') as HTMLDivElement
    let videoEle = popupEle.querySelector('.popup-video2-video') as HTMLVideoElement
    let titleEle = popupEle.querySelector('.popup-video2-title') as HTMLDivElement
    if (!popupEle) { return }
    if (!param || !param.url) { return }
    if (videoEle) {
      videoEle.src = param.url
      videoEle.play().then().catch()
    }
    if (titleEle) {
      if (param.title) {
        titleEle.innerText = param.title
        titleEle.classList.remove('hide')
      } else {
        titleEle.classList.add('hide')
      }
    }
    popupEle.classList.add('on')
  }
  popupVideo2Close () {
    let popupEle = document.querySelector('.popup-video2') as HTMLDivElement
    let videoEle = popupEle.querySelector('.popup-video2-video') as HTMLVideoElement
    if (videoEle) { videoEle.pause() }
    if (popupEle) { popupEle.classList.remove('on') }
  }
  lockBoxOpen (boxEle: HTMLDivElement) {
    console.log('lockBoxOpen')
    if (!boxEle) { return }
    let contentEle = boxEle.querySelector('.lock-content')
    let iptWrapEle = boxEle.querySelector('.lock-ipt')
    let iptEle = boxEle.querySelector('.lock-pwd') as HTMLInputElement
    let hash = boxEle.getAttribute('data-lockpwd')
    if (!iptEle || !iptEle.value) { return }
    let shaObj = new jsSHA('SHA-512', 'TEXT')
    shaObj.update(iptEle.value)
    let iptHash = shaObj.getHash('HEX')
    if (iptHash === hash) {
      if (contentEle) { contentEle.classList.remove('lock-hide') }
      if (iptWrapEle) { iptWrapEle.classList.add('lock-hide') }
    } else {
      this.$message.error('密码错误')
    }
  }
  mounted () {
    let anchor = null
    // 兼容prerender-spa-plugin
    Lib.dispatchEvent(document, 'custom-render-trigger')
    let btnConfirm = document.querySelector('.chain-btn-confirm')
    let inputPassport: HTMLInputElement | null = document.querySelector('.chain-input-passport .el-input__inner')
    if (btnConfirm) {
      btnConfirm.addEventListener('click', () => {
        let val = ''
        if (inputPassport) { val = inputPassport.value }
        this.confirm(val)
      })
    }
    if (inputPassport) {
      inputPassport.addEventListener('keyup', (event: KeyboardEvent) => {
        let isEnter = false
        if (event.key === 'Enter') {
          isEnter = true
        } else if (event.key === '13') {
          isEnter = true
        }
        if (isEnter) {
          let val = ''
          if (inputPassport) { val = inputPassport.value }
          this.confirm(val)
        }
      })
    }
    if (anchor) {
      document.documentElement.scrollTop = (anchor as HTMLElement).offsetTop
      document.body.scrollTop = (anchor as HTMLElement).offsetTop
    }
    let btnClose = document.querySelector('.video-popup-close-box')
    let btnVideoKetang = document.querySelector('.product-video-ketang') as Element
    let videoKetangCon = btnVideoKetang.querySelectorAll('.video-content-box')
    let btnNavCourse = document.querySelector('.chain-nav-course')
    let btnNavMofang = document.querySelector('.chain-nav-mofang')
    let btnNavExperience = document.querySelector('.chain-nav-experience')
    let btnNavOther = document.querySelector('.chain-nav-other')
    let btnNavSong = document.querySelector('.chain-nav-song')
    let btnNavHomework = document.querySelector('.chain-nav-homework')
    let btnNavPrice = document.querySelector('.chain-nav-price')
    let btnNavDownload = document.querySelector('.chain-nav-download')
    let btnNavSchool = document.querySelector('.chain-nav-school')
    let btnNavDmqzxs = document.querySelector('.chain-nav-dmqzxs')
    let btnNavManual = document.querySelector('.chain-nav-manual')
    let btnNavProperty = document.querySelector('.chain-nav-property')
    let btnNavChain = document.querySelector('.chain-nav-chain')
    let btnNavTaobao = document.querySelector('.chain-nav-taobao')
    let btnNavTrain = document.querySelector('.chain-nav-train')
    let btnNavGuwen = document.querySelector('.chain-nav-guwen')
    let btnNavKetang = document.querySelector('.product-nav-ketang')
    let btnNavTeacher = document.querySelector('.product-nav-teacher')
    let btnVideoBoxList = document.querySelectorAll('.video-box')
    let btnPopupVideo2Close = document.querySelector('.popup-video2-close')
    let btnLockConfirmList = document.querySelectorAll('.lock-confirm')
    let btnLockPasswordList = document.querySelectorAll('.lock-pwd') /* as NodeListOf<HTMLInputElement> */
    let btnMayPrimary = document.querySelector('.shixun51-primary')
    let btnMayAdvanced = document.querySelector('.shixun51-advanced')
    let btnMayAssess = document.querySelector('.shixun51-assess')
    let btnNavStandard = document.querySelector('.chain-nav-standard')
    let st = [1,2,3,4,5,6]
    for (let i = 0 ; i < st.length ; i++) {
      let img = document.querySelector('.product-video-img' + st[i])
      let btn = document.querySelector('.product-video-btn' + st[i])
      let group = document.querySelector('.product-video-group' + st[i])
      if (group) {
        (group as any).style.display = 'none'
      }
      if (btn) {
        btn.addEventListener('click', (e: any) => {
          this.clickback(e)
        })
      }
      if (img) {
        img.addEventListener('click', (e: any) => {
          this.clickimg(e)
        })
      }
    }

    if (btnNavCourse) {
      btnNavCourse.addEventListener('click', () => {
        this.showPanel('course')
      })
    }
    if (btnNavExperience) {
      btnNavExperience.addEventListener('click', () => {
        this.showPanel('experience')
      })
    }
    if (btnNavOther) {
      btnNavOther.addEventListener('click', () => {
        this.showPanel('other')
      })
    }
    if (btnNavSong) {
      btnNavSong.addEventListener('click', () => {
        this.showPanel('song')
      })
    }
    if (btnNavHomework) {
      btnNavHomework.addEventListener('click', () => {
        this.showPanel('homework')
      })
    }
    if (btnNavPrice) {
      btnNavPrice.addEventListener('click', () => {
        this.showPanel('price')
      })
    }
    if (btnNavDownload) {
      btnNavDownload.addEventListener('click', () => {
        this.showPanel('download')
      })
    }
    if (btnNavSchool) {
      btnNavSchool.addEventListener('click', () => {
        this.showPanel('school')
      })
    }
    if (btnNavDmqzxs) {
      btnNavDmqzxs.addEventListener('click', () => {
        this.showPanel('dmqzxs')
      })
    }
    if (btnNavManual) {
      btnNavManual.addEventListener('click', () => {
        this.showPanel('manual')
      })
    }
    if (btnNavProperty) {
      btnNavProperty.addEventListener('click', () => {
        this.showPanel('property')
      })
    }
    if (btnNavChain) {
      btnNavChain.addEventListener('click', () => {
        this.showPanel('chain')
      })
    }
    if (btnNavTaobao) {
      btnNavTaobao.addEventListener('click', () => {
        this.showPanel('taobao')
      })
    }
    if (btnNavMofang) {
      btnNavMofang.addEventListener('click', () => {
        this.showPanel('mofang')
      })
    }
    if (btnNavTrain) {
      btnNavTrain.addEventListener('click', () => {
        this.showPanel('train')
      })
    }
    if (btnNavGuwen) {
      btnNavGuwen.addEventListener('click', () => {
        this.showPanel('guwen')
      })
    }
    if (btnNavStandard) {
      btnNavStandard.addEventListener('click', () => {
        this.showPanel('standard')
      })
    }
    if (btnClose) {
      btnClose.addEventListener('click', () => {
        this.videoClose()
        if (sessionStorage.clickcount) {
          // 判断是否是用户主动关闭声音，是就不播放
          if (this.audio && sessionStorage.appMusicState === 'false') {
            this.audio.play().catch()
            this.audio.currentTime = sessionStorage.clickcount
            if (this.component)this.component.classList.remove('paused')
          }
        }
      })
    }
    if (videoKetangCon) {
      videoKetangCon.forEach((v, i) => {
        let img = v.querySelector('.video-content-img') as HTMLImageElement
        let alt = img.alt
        v.addEventListener('click', () => {
          // this.videoPlay(linkArr[i])
          if (alt) {
            this.videoPlay('/media/product-' + alt + '.mp4')
          }
        })
      })
    }
    if (btnVideoBoxList) {
      btnVideoBoxList.forEach((videoDiv, index) => {
        let url = videoDiv.getAttribute('data-video-url')
        if (!url || url === '') { return }
        url = this.staticPath + url
        let title = undefined
        let titleELe = videoDiv.querySelector('.video-box-title')
        if (titleELe) { title = titleELe.innerHTML }
        let param: PopupVideo2Param = {
          url,
          title
        }
        videoDiv.addEventListener('click', (event) => {
          this.popupVideo2Show(param)
        })
      })
    }
    if (btnPopupVideo2Close) {
      btnPopupVideo2Close.addEventListener('click', () => {
        this.popupVideo2Close()
      })
    }
    if (btnLockConfirmList) {
      btnLockConfirmList.forEach(btn => {
        btn.addEventListener('click', (event) => {
          event.preventDefault()
          let aEle = event.currentTarget as HTMLAnchorElement
          let boxEle: HTMLDivElement | null = null
          let contentEle = null
          let iptEle = null
          if (aEle) { boxEle = aEle.closest('.lock-box') as HTMLDivElement }
          if (boxEle) {
            this.lockBoxOpen(boxEle)
          }
        })
      })
    }
    if (btnLockPasswordList) {
      btnLockPasswordList.forEach(ipt => {
        ipt.addEventListener('keydown', (event: any) => {
          if (event.key === 'Enter') {
            let boxEle = ipt.closest('.lock-box') as HTMLDivElement
            if (boxEle) { this.lockBoxOpen(boxEle) }
          }
        })
      })
    }
    /* let img1: any = document.querySelector('.img1')
    let img2: any = document.querySelector('.img2')
    let img3: any = document.querySelector('.img3')
    let img4: any = document.querySelector('.img4')
    let img5: any = document.querySelector('.img5')
    let img6: any = document.querySelector('.img6')
    let img7: any = document.querySelector('.img7')
    let img8: any = document.querySelector('.img8')
    let img9: any = document.querySelector('.img9')
    let img10: any = document.querySelector('.img10')
    if (img1) {
      img1.addEventListener('click', () => {
        this.showvideo(1)
      })
    }
    if (img2) {
      img2.addEventListener('click', () => {
        this.showvideo(2)
      })
    }
    if (img3) {
      img3.addEventListener('click', () => {
        this.showvideo(3)
      })
    }
    if (img4) {
      img4.addEventListener('click', () => {
        this.showvideo(4)
      })
    }
    if (img5) {
      img5.addEventListener('click', () => {
        this.showvideo(5)
      })
    }
    if (img6) {
      img6.addEventListener('click', () => {
        this.showvideo(6)
      })
    }
    if (img7) {
      img7.addEventListener('click', () => {
        this.showvideo(7)
      })
    }
    if (img8) {
      img8.addEventListener('click', () => {
        this.showvideo(8)
      })
    }
    if (img9) {
      img9.addEventListener('click', () => {
        this.showvideo(9)
      })
    }
    if (img10) {
      img10.addEventListener('click', () => {
        this.showvideo(10)
      })
    } */
    this.showPanel()

    // 金桔思维下载
    let downloadSiwei = document.querySelector('.product-item-download-siwei')
    let btnCloseSiwei = document.querySelector('.product-item-close-siwei')
    let btnDownloadSiwei = document.querySelector('.product-btn-download-siwei')
    if (btnDownloadSiwei && downloadSiwei) {
      btnDownloadSiwei.addEventListener('click', () => {
        downloadSiwei!.classList.add('show')
      })
    }
    if (btnCloseSiwei && downloadSiwei) {
      btnCloseSiwei.addEventListener('click', () => {
        downloadSiwei!.classList.remove('show')
      })
    }
      // 五一师训
    let mayPrimary = document.querySelector('.primary-51')
    let mayadvanced = document.querySelector('.advanced-51')
    let mayAssess = document.querySelector('.assess-51')
    if (btnMayPrimary) {
      btnMayPrimary.addEventListener('click', () => {
        mayPrimary!.classList.remove('hide')
        mayadvanced!.classList.remove('show')
        mayAssess!.classList.remove('show')
        btnMayPrimary!.classList.remove('leave')
        btnMayAdvanced!.classList.remove('on')
        btnMayAssess!.classList.remove('on')
      })
    }
    if (btnMayAdvanced) {
      btnMayAdvanced.addEventListener('click', () => {
        mayadvanced!.classList.add('show')
        mayPrimary!.classList.add('hide')
        mayAssess!.classList.remove('show')
        btnMayPrimary!.classList.add('leave')
        btnMayAdvanced!.classList.add('on')
        btnMayAssess!.classList.remove('on')
      })
    }
    if (btnMayAssess) {
      btnMayAssess.addEventListener('click', () => {
        mayAssess!.classList.add('show')
        mayPrimary!.classList.add('hide')
        mayadvanced!.classList.remove('show')
        btnMayPrimary!.classList.add('leave')
        btnMayAdvanced!.classList.remove('on')
        btnMayAssess!.classList.add('on')
      })
    }
  }

  clickback (e: any) {
    let st = [1,2,3,4,5,6,7,8,9,10,11,12]
    let idx = 1
    for (let i = 0 ; i < st.length ; i++) {
      let img = document.querySelector('.product-video-img' + st[i])
      let group = document.querySelector('.product-video-group' + st[i])
      if (group) {
        (group as any).style.display = 'none'
      }
      // (document.querySelector('.product-video-group' + st[i]) as any).style.display = 'none'
      if (img) {
        (img as any).style.display = 'inline'
      }
    }
  }

  clickimg (e: any) {
    console.log(e)
    let st = [1,2,3,4,5,6,7,8,9,10,11,12]
    let idx = 1
    for (let i = 0 ; i < st.length ; i++) {
      let img = document.querySelector('.product-video-img' + st[i])
      let group = document.querySelector('.product-video-group' + st[i])
      if (group) {
        (group as any).style.display = 'none'
      }
      if (img) {
        (img as any).style.display = 'none'
      }
      if (img === e.target) idx = st[i]
    }
    let groupp = document.querySelector('.product-video-group' + idx)
    if (groupp) {
      (groupp as any).style.display = 'block'
    }
  }
}
