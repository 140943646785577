import { render, staticRenderFns } from "./GuideSiwei.vue?vue&type=template&id=77d336ec&scoped=true&"
import script from "./GuideSiwei.vue?vue&type=script&lang=ts&"
export * from "./GuideSiwei.vue?vue&type=script&lang=ts&"
import style0 from "./GuideSiwei.vue?vue&type=style&index=0&id=77d336ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77d336ec",
  null
  
)

component.options.__file = "GuideSiwei.vue"
export default component.exports