import { render, staticRenderFns } from "./Advert6.vue?vue&type=template&id=0ede5bb6&scoped=true&"
import script from "./Advert6.vue?vue&type=script&lang=ts&"
export * from "./Advert6.vue?vue&type=script&lang=ts&"
import style0 from "./Advert6.vue?vue&type=style&index=0&id=0ede5bb6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ede5bb6",
  null
  
)

component.options.__file = "Advert6.vue"
export default component.exports