











import { Component, Vue, Inject } from 'vue-property-decorator'
// import { mapState } from 'vuex' 自动播放 autoplay
// 引入mp3

// @ts-ignore
import musicDM from '../../assets/audio/bg-dm.mp3'

// import Bus from './bus.js'

@Component
export default class AppMusicPlayer extends Vue {
  audio: HTMLAudioElement | null = null
  component: HTMLAudioElement | null = null
  musicPath: string = app.static + '/audio/bg-dm.mp3'
  appDollState: boolean | undefined
  // 控制show的显示隐藏
  // 控制show的显示隐藏
  showMusicPlayer () {
    if (this.component) { this.component.classList.remove('none') }
  }
  // 播放器状态同步
  playerStatusSync () {

    if (!this.component || !this.audio) { return console.debug('[AppMusicPlayer] playerStatusSync 未发现播放器组件') }
    if (this.audio.paused) {
      console.debug('[AppMusicPlayer] playerStatusSync this.audio.paused:', this.audio.paused)
      // 播放按钮显示paused
      this.component.classList.add('paused')
    } else {
      console.debug('[AppMusicPlayer] playerStatusSync this.audio.paused:', this.audio.paused)
      this.component.classList.remove('paused')
    }
  }
  play () {
    if (!this.component || !this.audio) { return console.debug('[AppMusicPlayer] play 未发现播放器组件') }
    console.debug('[AppMusicPlayer] play')
    // 播放音乐
    this.audio.play().catch()
    if (sessionStorage.clickcount) {
      this.audio.currentTime = sessionStorage.clickcount
    }
    // 播放按钮显示paused
    this.component.classList.remove('paused')
    if (sessionStorage) { sessionStorage.appMusicPlayerPaused = 'false' }
  }
  pause () {
    if (!this.component || !this.audio) { return console.debug('[AppMusicPlayer] pause 未发现播放器组件') }
    console.debug('[AppMusicPlayer] pause')
    if (this.audio.currentTime !== 0) {
      sessionStorage.clickcount = Number(this.audio.currentTime)
      // this.audio.currentTime = sessionStorage.clickcount
    }
    // 暂停音乐
    if (this.audio) { this.audio.pause() }
    // 暂停按钮显示
    if (this.component) { this.component.classList.add('paused') }
    if (sessionStorage) { sessionStorage.appMusicPlayerPaused = 'true' }
  }
  toggle () {
    // 判断播放状态
    if (this.audio && this.audio.paused) {
      this.play()
    } else {
      this.pause()
    }
  }
  // 设置时间
  setTime () {
    if (this.audio) {
      if (this.audio.currentTime !== 0) {
        console.log('time' + this.audio.currentTime)
        sessionStorage.clickcount = Number(this.audio.currentTime)
      }
    }
  }
  mounted () {
    console.debug('[AppMusicPlayer] mounted')
    // 音乐audio
    this.audio = document.querySelector('.app-music-player-audio')
    // component最外面的容器
    this.component = document.querySelector('.component-app-music-player')
    // 音频初始状态
    if (this.audio) { this.audio.src = musicDM }
    if (sessionStorage && sessionStorage.appMusicPlayerPaused === 'true') { this.pause() }
    console.log('.....sessionStorage.appDollState' + sessionStorage.appDollState)
    if (!sessionStorage.appDollState) {
      sessionStorage.appDollState = 'false'
    }
    if (sessionStorage.appDollState !== 'false') {
      // 隐藏公仔
      // this.showMusicPlayer()
      console.log('不展示公仔')
      if (this.component) { this.component.classList.add('none') }
      if (this.audio) { this.audio.pause() }
    } else {
      // 显示公仔
      this.showMusicPlayer()
      // 设置状态
      setTimeout(() => {
        this.playerStatusSync()
      }, 1000)
    }
    if (sessionStorage.clickcount) {
      if (this.audio) {
        this.audio.currentTime = sessionStorage.clickcount
      }
    }
    // 按钮
    let btnPlay = document.querySelector('.app-music-player-btn-play')
    let btnClose = document.querySelector('.app-music-player-btn-close')
    let elf = document.querySelector('.app-music-player-elf')
    // 音乐播放
    if (btnPlay) {
      btnPlay.addEventListener('click', () => {
        this.toggle()
      })
    }
    // 关闭小公仔
    if (btnClose) {
      btnClose.addEventListener('click', () => {
        if (this.component) { this.component.classList.add('none') }
        // 如果是播放就暂停音乐
        if (this.audio && !this.audio.paused) {
          this.audio.pause()
        }
        sessionStorage.appDollState = 'true'
      })
    }
    let beginTime = 0 // 开始时间
    let differTime = 0 // 时间差
    // 关闭前
    window.onbeforeunload = () => {
      // 设置时间
      this.setTime()
    }
  }
}
