

























import { Vue, Component, Prop } from 'vue-property-decorator'
// import appMusicPlayer from '@/components/AppMusicPlay/AppMusicPlay.vue'
@Component
export default class AppHead extends Vue {
  @Prop({ default: '/' }) active!: string
  @Prop({ default: 'on' }) activeClassName!: string

  audio: HTMLAudioElement | null = null
  component: HTMLAudioElement | null = null

  to (path: string) {
    Lib.router.push(path)
  }
  mounted () {
    // 获取nav添加点击事件获取当前音乐的播放进度
    let btnNav = document.querySelectorAll('.nav')
    if (btnNav) {
      btnNav.forEach((v, i) => {
        v.addEventListener('click', () => {
          // this.videoPlay(linkArr[i])
          this.audio = document.querySelector('.app-music-player-audio')
          this.component = document.querySelector('.component-app-music-player')
          if (this.audio) {
            sessionStorage.clickcount = Number(this.audio.currentTime)
          }
        })
      })
    }
  }
}
