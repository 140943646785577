

















import { Vue, Component } from 'vue-property-decorator'
// @ts-ignore
import { Slider, SliderItem } from 'vue-easy-slider'
@Component({ components: { Slider, SliderItem } })
export default class Trained extends Vue {
  sliderIndex: number = 0
  sliderValue: number = 0
  sliderItemList: NodeListOf<HTMLDivElement> | null = null
  dotsList: NodeListOf<HTMLSpanElement> | null = null
  timer: any = null
  currentIndex: number = 0
  list: string[] = []
  staticPath: string = 'https://s.doumiao9.com/official'
  listLength () {
    // console.log('listLength')有反应
    return this.list.length - 1
  }
  go (toIndex: number) {
    // console.debug('[Speech] to this.sliderItemList:', this.sliderItemList)
    if (!this.sliderItemList || this.sliderItemList.length < 1) { return }
    if (toIndex < 0) { toIndex = 0 }
    if (this.sliderItemList.length - 1 < toIndex) { toIndex = this.sliderItemList.length - 1 }
    let prev = toIndex - 1
    let next = toIndex + 1
    if (prev < 0) { prev = this.sliderItemList.length - 1 }
    if (this.sliderItemList.length - 1 < next) { next = 0 }
    this.sliderItemList.forEach((item, index) => {
      item.classList.remove('prev', 'active', 'next')
      if (index === toIndex) {
        item.classList.add('active')
      } else if (index === prev) {
        item.classList.add('prev')
      } else if (index === next) {
        item.classList.add('next')
      }
    })
    this.setDotActive(toIndex)
    this.currentIndex = toIndex
  }
  prev () {
    console.debug('[Speech] prev')
    if (!this.sliderItemList) { return }
    let to = this.currentIndex - 1
    if (to < 0) {
      to = this.sliderItemList.length - 1
    }
    this.go(to)
  }
  next () {
    console.debug('[Speech] next')
    if (!this.sliderItemList) { return }
    let to = this.currentIndex + 1
    if (to > this.sliderItemList.length - 1) {
      to = 0
    }
    this.go(to)
  }
  setDotActive (toIndex: number) {
    if (!this.dotsList || this.dotsList.length < 1) { return }
    this.dotsList.forEach((dot, index) => {
      if (index === toIndex) {
        dot.classList.add('on')
      } else {
        dot.classList.remove('on')
      }
    })
  }
  setAutoPlay () {
    this.timer = setInterval(() => {
      this.next()
    }, 5000)
    // console.debug('setAutoPlay')
    // console.log('setAutoPlay') //有反应
    // // this.timer = window.setInterval(() => {
    // //   let nextIndex = this.sliderIndex + 1
    // //   if (nextIndex > 44) {
    // //     // 超过最大值，重头开始播放
    // //     nextIndex = 0
    // //   }
    //   //   document.querySelectorAll('.slider-item').forEach((i, index) => {
    // //   this.setItemClass((i as HTMLButtonElement), index)
    // //   console.log('slider-item')
    // // })
    // //   this.next()
    // // }, 5000)
    // this.timer = setInterval(() => {
    //   this.next()
    //   this.soliderItem()
    // }, 5000)
  }
  // soliderItem() {
  //   document.querySelectorAll('.slider-item').forEach((i, index) => {
  //     this.setItemClass((i as HTMLButtonElement), index)
  //     console.log('slider-item')
  //     })
  // }
  stopAutoPlay () {
    clearInterval(this.timer)
  }
  // setDotActiveStyl (i: any) {
  //   if (i == this.currentIndex) {
  //     return { backgroundColor: '#D33A31' }
  //   }
  // }
  // setActiveItem (index: number ) {
  //   (this.$refs.carousel as any).setActiveItem(index)
  // }
  mounted () {
    let arrowLeft = document.querySelector('.arrow-left')
    let arrowRight = document.querySelector('.arrow-right')
    let mouseMove = document.querySelector('.slider')
    let mouseLeave = document.querySelector('.slider')
    // let dotsSpan = document.querySelector('.dots-span')
    this.setAutoPlay()
    // document.querySelectorAll('.slider-item').forEach((i, index) => {
    //   this.setItemClass(index)
    //   console.log('slider-item')
    // })
    // 左右切换
    if (arrowLeft) {
      arrowLeft.addEventListener('click', event => {
        event.preventDefault()
        this.prev()
      })
    }
    if (arrowRight) {
      arrowRight.addEventListener('click', event => {
        event.preventDefault()
        this.next()
        // this.soliderItem()
      })
    }
    // 鼠标悬停
    if (mouseMove) {
      mouseMove.addEventListener('mousemove', event => {
        event.preventDefault()
        this.stopAutoPlay()
        // console.log("悬停mouseMove")
      })
    }
    if (mouseLeave) {
      mouseLeave.addEventListener('mouseleave', event => {
        event.preventDefault()
        this.setAutoPlay()
        // console.log("悬停mouseLeave")
      })
    }
    for (let i = 1; i <= 44; i++) {
      this.list.push(this.staticPath + '/img/trained/testimonial/shixun-img_' + i + '.jpg')
    }

    // 初始化
    this.$nextTick().then(() => {
      this.sliderItemList = document.querySelectorAll('.slider-item')
      this.dotsList = document.querySelectorAll('.dots-span')
      this.go(0)
    }).catch()
  }
}
