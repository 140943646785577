















































































































































































































































































































































































































































import { Component, Vue, Inject } from 'vue-property-decorator'
import Advert from '@/components/Advert/Advert.vue'
import Advert2 from '@/components/Advert/Advert2.vue'
import Advert3 from '@/components/Advert/Advert3.vue'
import Advert4 from '@/components/Advert/Advert4.vue'
import Advert5 from '@/components/Advert/Advert5.vue'
import Advert6 from '@/components/Advert/Advert6.vue'
@Component({ components: { Advert, Advert2, Advert3, Advert4, Advert5, Advert6 } })
export default class Buy extends Vue {
  loaded: boolean = false // 页面是否已经加载完成
  to (path: string) {
    Lib.router.push(path)
  }
  back () {
    Lib.router.go(-1)
  }
  download (path: string) {
    window.open(path)
  }
  opentcQrcode (i: string) {
    let detail1 = document.querySelector('.detail-1') as HTMLDivElement
    // let detail2 = document.querySelector('.detail-2') as HTMLDivElement
    // let detail3 = document.querySelector('.detail-3') as HTMLDivElement
    // let detail4 = document.querySelector('.detail-4') as HTMLDivElement
    let detailPrice = document.querySelector('.detail-price') as HTMLParagraphElement
    let priceImg = document.querySelector('.price-img') as HTMLImageElement
    detail1.innerHTML = ''
    // detail2.innerHTML = ''
    // detail3.innerHTML = ''
    // detail4.innerHTML = ''
    detailPrice.innerText = ''
    priceImg.src = ''
    if (i === '1') {
      priceImg.src = require('../assets/buy-chuti.jpg')
      detailPrice.innerText = '599元/年/帐号'
      detail1.innerHTML = '豆苗出题-基础版<br>（只有打印功能无下载功能）'
    }
    if (i === '2') {
      priceImg.src = require('../assets/buy-chuti.jpg')
      detailPrice.innerText = '899元/年/帐号'
      detail1.innerHTML = '豆苗出题-专业版<br>（只有打印功能无下载功能）<br>（2018年11月1日开始，免费开放新增功能不支持下载 ，新增功能仅可打印）'
    }
    if (i === '3') {
      priceImg.src = require('../assets/buy-dmkt.jpg')
      detailPrice.innerText = '599元/年/帐号'
      detail1.innerHTML = '豆苗课堂'
    }
    if (i === '4') {
      priceImg.src = require('../assets/buy-dmkt.jpg')
      detailPrice.innerText = '899元/年/帐号'
      detail1.innerHTML = '豆苗课堂-专业版'
    }
    if (i === '5') {
      priceImg.src = require('../assets/buy-shansuan.jpg')
      detailPrice.innerText = '298元/年/帐号'
      detail1.innerHTML = '豆苗闪算（APP）'
    }
    if (i === '6') {
      priceImg.src = require('../assets/buy-kt-chuti.jpg')
      detailPrice.innerText = '899元/年'
      detail1.innerHTML = '豆苗出题-基础版账号1个<br>豆苗课堂-基础版账号1个'
    }
    if (i === '7') {
      priceImg.src = require('../assets/buy-kt-chuti.jpg')
      detailPrice.innerText = '1199元/年'
      detail1.innerHTML = '豆苗出题-基础版账号1个<br>豆苗课堂-专业版账号1个'
    }
    if (i === '8') {
      priceImg.src = require('../assets/buy-kt-chuti.jpg')
      detailPrice.innerText = '1299元/年'
      detail1.innerHTML = '豆苗出题-基础版账号1个<br>豆苗课堂-基础版账号2个'
    }
    if (i === '9') {
      priceImg.src = require('../assets/buy-kt-chuti.jpg')
      detailPrice.innerText = '1899元/年'
      detail1.innerHTML = '豆苗出题-基础版账号1个<br>豆苗课堂-专业版账号2个'
    }
    if (i === '10') {
      priceImg.src = require('../assets/buy-kt-chuti.jpg')
      detailPrice.innerText = '1199元/年'
      detail1.innerHTML = '豆苗出题-专业版账号1个<br>豆苗课堂-基础版账号1个'
    }
    if (i === '11') {
      priceImg.src = require('../assets/buy-kt-chuti.jpg')
      detailPrice.innerText = '1399元/年'
      detail1.innerHTML = '豆苗出题-专业版账号1个<br>豆苗课堂-专业版账号1个'
    }
    if (i === '12') {
      priceImg.src = require('../assets/buy-kt-chuti.jpg')
      detailPrice.innerText = '1599元/年'
      detail1.innerHTML = '豆苗出题-专业版账号1个<br>豆苗课堂-基础版账号2个'
    }
    if (i === '13') {
      priceImg.src = require('../assets/buy-kt-chuti.jpg')
      detailPrice.innerText = '2099元/年'
      detail1.innerHTML = '豆苗出题-专业版账号1个<br>豆苗课堂-专业版账号2个'
    }
    if (i === '14') {
      priceImg.src = require('../assets/buy-kt-chuti.jpg')
      detailPrice.innerText = '2156元/年（8折）'
      detail1.innerHTML = '豆苗课堂-基础版账号3个<br>豆苗出题-专业版账号1个<br>赠送“豆苗闪算”（APP）1个<br><br>会员期间，都能免费享受会员段位套餐对应的软件所有的升级！'
    }
    if (i === '15') {
      priceImg.src = require('../assets/buy-kt-chuti.jpg')
      detailPrice.innerText = '2876元/年（8折）'
      detail1.innerHTML = '豆苗课堂-专业版账号3个<br>豆苗出题-专业版账号1个<br>赠送“豆苗闪算”（APP）1个<br><br>会员期间，都能免费享受会员段位套餐对应的软件所有的升级！'
    }
    if (i === '16') {
      priceImg.src = require('../assets/buy-kt-chuti.jpg')
      detailPrice.innerText = '3115元/年（8折）'
      detail1.innerHTML = '豆苗课堂-基础版账号5个<br>豆苗出题-专业版账号1个<br>赠送“豆苗闪算”（APP）2个<br><br>会员期间，都能免费享受会员段位套餐对应的软件所有的升级！'
    }
    if (i === '17') {
      priceImg.src = require('../assets/buy-kt-chuti.jpg')
      detailPrice.innerText = '4315元/年（8折）'
      detail1.innerHTML = '豆苗课堂-专业版账号5个<br>豆苗出题-专业版账号1个<br>赠送“豆苗闪算”（APP）2个<br><br>会员期间，都能免费享受会员段位套餐对应的软件所有的升级！'
    }
  }
  created () {
    this.loaded = true
  }
  mounted () {
    Lib.dispatchEvent(document, 'custom-render-trigger')
    let buy1 = document.querySelector('.btn-buy-qrcode1')
    let buy2 = document.querySelector('.btn-buy-qrcode2')
    let buy3 = document.querySelector('.btn-buy-qrcode3')
    let buy4 = document.querySelector('.btn-buy-qrcode4')
    let buy5 = document.querySelector('.btn-buy-qrcode5')
    let price1 = document.querySelector('.btn-price-qrcode1')
    let price2 = document.querySelector('.btn-price-qrcode2')
    let price3 = document.querySelector('.btn-price-qrcode3')
    let price4 = document.querySelector('.btn-price-qrcode4')
    let price5 = document.querySelector('.btn-price-qrcode5')
    let price6 = document.querySelector('.btn-price-qrcode6')
    let price7 = document.querySelector('.btn-price-qrcode7')
    let price8 = document.querySelector('.btn-price-qrcode8')
    let price9 = document.querySelector('.btn-price-qrcode9')
    let price10 = document.querySelector('.btn-price-qrcode10')
    let price11 = document.querySelector('.btn-price-qrcode11')
    let price12 = document.querySelector('.btn-price-qrcode12')
    let priceTips = document.querySelector('.price-tips') as HTMLDivElement
    let closeTips = document.querySelector('.close-tips')
    document.querySelectorAll('.btn-buy-qrcode').forEach((btn, i) => {
      btn.addEventListener('click', (event) => {
        event.preventDefault()
        priceTips.classList.add('active')
      })
    })
    if (closeTips) {
      closeTips.addEventListener('click', (event) => {
        event.preventDefault()
        priceTips.classList.remove('active')
      })
    }
    if (buy1) {
      buy1.addEventListener('click', (event) => {
        event.preventDefault()
        this.opentcQrcode('1')
      })
    }
    if (buy2) {
      buy2.addEventListener('click', (event) => {
        event.preventDefault()
        this.opentcQrcode('2')
      })
    }
    if (buy3) {
      buy3.addEventListener('click', (event) => {
        event.preventDefault()
        this.opentcQrcode('3')
      })
    }
    if (buy4) {
      buy4.addEventListener('click', (event) => {
        event.preventDefault()
        this.opentcQrcode('4')
      })
    }
    if (buy5) {
      buy5.addEventListener('click', (event) => {
        event.preventDefault()
        this.opentcQrcode('5')
      })
    }
    if (price1) {
      price1.addEventListener('click', (event) => {
        event.preventDefault()
        this.opentcQrcode('6')
      })
    }
    if (price2) {
      price2.addEventListener('click', (event) => {
        event.preventDefault()
        this.opentcQrcode('7')
      })
    }
    if (price3) {
      price3.addEventListener('click', (event) => {
        event.preventDefault()
        this.opentcQrcode('8')
      })
    }
    if (price4) {
      price4.addEventListener('click', (event) => {
        event.preventDefault()
        this.opentcQrcode('9')
      })
    }
    if (price5) {
      price5.addEventListener('click', (event) => {
        event.preventDefault()
        this.opentcQrcode('10')
      })
    }
    if (price6) {
      price6.addEventListener('click', (event) => {
        event.preventDefault()
        this.opentcQrcode('11')
      })
    }
    if (price7) {
      price7.addEventListener('click', (event) => {
        event.preventDefault()
        this.opentcQrcode('12')
      })
    }
    if (price8) {
      price8.addEventListener('click', (event) => {
        event.preventDefault()
        this.opentcQrcode('13')
      })
    }
    if (price9) {
      price9.addEventListener('click', (event) => {
        event.preventDefault()
        this.opentcQrcode('14')
      })
    }
    if (price10) {
      price10.addEventListener('click', (event) => {
        event.preventDefault()
        this.opentcQrcode('15')
      })
    }
    if (price11) {
      price11.addEventListener('click', (event) => {
        event.preventDefault()
        this.opentcQrcode('16')
      })
    }
    if (price12) {
      price12.addEventListener('click', (event) => {
        event.preventDefault()
        this.opentcQrcode('17')
      })
    }
  }
}
