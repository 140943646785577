import Vue from 'vue'
import Router from 'vue-router'
import { Route } from '../node_modules/vue-router/types'
import Index from './views/Index.vue'
import Login from './views/Login.vue'
import Teacher from './views/Teacher.vue'
import Shansuan from './views/Shansuan.vue'
import GuideKetang from './views/GuideKetang.vue'
import GuideChuti from './views/GuideChuti.vue'
import GuideSiwei from './views/GuideSiwei.vue'
import InstallKetang from './views/InstallKetang.vue'
import InstallChuti from './views/InstallChuti.vue'
import InstallShansuan from './views/InstallShansuan.vue'
import InstallTeacher from './views/InstallTeacher.vue'
import InstallSiwei from './views/InstallSiwei.vue'
import FeatureShansuan from './views/FeatureShansuan.vue'
import Product from './views/Product.vue'
import Download from './views/Download.vue'
import Contact from './views/Contact.vue'
import Buy from './views/Buy.vue'
import FQA from './views/FQA.vue'
import Activity from './views/Activity.vue'
import Voice from './views/Voice.vue'
import Chain from './views/Chain.vue'
import Recruited from './views/Recruited.vue'
import Trained from './views/Trained.vue'
import Cube from './views/Cube.vue'
import News from './views/News/Index.vue'
import News1 from './views/News/1.vue'

Vue.use(Router)

// let redirect = (to: Route, from: Route, next: any) => {
//   next({ path: '/product/', replace: true })
// }
let recordAccessHook = (to: Route, from: Route, next: any) => {
  // let routerEvent = Lib.router.recordEvent
  log.debug('[Router recordAccessHook]')
  if (Lib.store.state.user.token) {
    log.debug('[Router recordAccessHook] 用户已登录')
    setTimeout(() => {
      SApi.recordAccess({
        gameName: 'official',
        gameVersion: app.release,
        gameParams: JSON.stringify({
          path: to.path,
          query: to.query
        }),
        timestamp: new Date().getTime()
      }).catch()
    }, 0)
  }
  next()
}

export default new Router({
  mode: 'history',
  // base: '/',
  routes: [
    { path: '/', name: 'index', component: Index, beforeEnter: recordAccessHook },
    { path: '/activity/', name: 'activity', component: Activity, beforeEnter: recordAccessHook },
    { path: '/product/', name: 'product', component: Product, beforeEnter: recordAccessHook },
    { path: '/buy/', name: 'buy', component: Buy, beforeEnter: recordAccessHook },
    { path: '/fqa/', name: 'fqa', component: FQA, beforeEnter: recordAccessHook },
    { path: '/voice/', name: 'voice', component: Voice, beforeEnter: recordAccessHook },
    { path: '/download/', name: 'download', component: Download, beforeEnter: recordAccessHook },
    { path: '/about/', name: 'about', component: Product, beforeEnter: recordAccessHook },
    { path: '/contact/', name: 'contact', component: Contact, beforeEnter: recordAccessHook },
    { path: '/product/guide/teacher/', name: 'teacher', component: Teacher, beforeEnter: recordAccessHook },
    { path: '/product/guide/shansuan/', name: 'shansuan', component: Shansuan, beforeEnter: recordAccessHook },
    { path: '/product/guide/ketang/', name: 'ketang', component: GuideKetang, beforeEnter: recordAccessHook },
    { path: '/product/guide/chuti/', name: 'chuti', component: GuideChuti, beforeEnter: recordAccessHook },
    { path: '/product/guide/siwei/', name: 'siwei', component: GuideSiwei, beforeEnter: recordAccessHook },
    { path: '/product/install/ketang/', name: 'ketang', component: InstallKetang, beforeEnter: recordAccessHook },
    { path: '/product/install/chuti/', name: 'chuti', component: InstallChuti, beforeEnter: recordAccessHook },
    { path: '/product/install/shansuan/', name: 'shansuan', component: InstallShansuan, beforeEnter: recordAccessHook },
    { path: '/product/install/teacher/', name: 'teacher', component: InstallTeacher, beforeEnter: recordAccessHook },
    { path: '/product/install/siwei/', name: 'siwei', component: InstallSiwei, beforeEnter: recordAccessHook },
    { path: '/product/feature/shansuan/', name: 'shansuan', component: FeatureShansuan, beforeEnter: recordAccessHook },
    { path: '/login/', name: 'login', component: Login, beforeEnter: recordAccessHook },
    { path: '/chain/', name: 'chain', component: Chain, beforeEnter: recordAccessHook },
    { path: '/recruited/', name: 'recruited', component: Recruited, beforeEnter: recordAccessHook },
    { path: '/news/', name: 'news', component: News, beforeEnter: recordAccessHook },
    { path: '/news/1/', name: 'news1', component: News1, beforeEnter: recordAccessHook },
    { path: '/news/2/', name: 'news2', component: News1, beforeEnter: recordAccessHook },
    { path: '/trained/', name: 'trained', component: Trained, beforeEnter: recordAccessHook },
    { path: '/cube/', name: 'cube', component: Cube, beforeEnter: recordAccessHook }
  ]
})
