







import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class VideoBox extends Vue {
  @Prop() videoUrl!: string
  @Prop() imgUrl!: string
  @Prop() index!: number
  staticPath: string = 'https://s.doumiao9.com/official'
  mounted () {
    let play: any = document.querySelectorAll('.playButton')[this.index]
    let img: any = document.querySelectorAll('.preview-img')[this.index]
    let videoBox: any = document.querySelectorAll('.video-box')[this.index]
    let plays: any = document.querySelectorAll('.playButton')
    let imgs: any = document.querySelectorAll('.preview-img')
    let videoBoxs: any = document.querySelectorAll('.video-box')
    // console.log(videoBoxs)
    if (play) {
      play.addEventListener('click', () => {
        console.log('按下播放按钮')
        console.log(videoBoxs.length)
        for (let i = videoBoxs.length - 1; i >= 0; i--) {
          if (videoBoxs[i].childNodes.length > 2) {
            console.log('删除子节点')
            videoBoxs[i].removeChild(videoBoxs[i].childNodes[2])
          }
        }
        for (let i = 0; i < imgs.length; i++) {
          imgs[i].classList.remove('none')
          plays[i].classList.remove('none')
        }
        img.classList.add('none')
        play.classList.add('none')
        console.log(videoBoxs)
        /* if (VideoBox) {
          video.innerHTML = '<video class="introduce-video" controls width="100%" src="' + this.videoUrl + '></video>'
        } */
        if (videoBox) {
          let ele = document.createElement('VIDEO')
          ele.setAttribute('class', 'introduce-video')
          ele.setAttribute('src', this.videoUrl)
          ele.setAttribute('controls', 'true')
          ele.setAttribute('autoplay', 'true')
          ele.setAttribute('height', '100%')
          ele.setAttribute('width', '100%')
          ele.setAttribute('preload', 'metadata')
          // let ele = document.createElement('<video class="chain-introduce-video" controls preload="metadata" src="' + this.staticPath  + '"/media/chain-10-2019112001.mp4"></video>')
          videoBox.appendChild(ele)
        }
      })
    }
  }
}
