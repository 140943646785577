import { render, staticRenderFns } from "./Advert2.vue?vue&type=template&id=2c2db103&scoped=true&"
import script from "./Advert2.vue?vue&type=script&lang=ts&"
export * from "./Advert2.vue?vue&type=script&lang=ts&"
import style0 from "./Advert2.vue?vue&type=style&index=0&id=2c2db103&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c2db103",
  null
  
)

component.options.__file = "Advert2.vue"
export default component.exports