




























import { Component, Vue, Inject } from 'vue-property-decorator'
import advert from '@/components/Advert/Advert.vue'
import advert2 from '@/components/Advert/Advert2.vue'
import advert3 from '@/components/Advert/Advert3.vue'
import advert4 from '@/components/Advert/Advert4.vue'
import Advert5 from '@/components/Advert/Advert5.vue'
import Advert6 from '@/components/Advert/Advert6.vue'
import SideNav from '@/components/SideNav/sideNav.vue'

@Component({ components: { advert, advert2, advert3, advert4, Advert5, SideNav, Advert6 } })
export default class Recruited extends Vue {
  loaded: boolean = false // 页面是否已经加载完成
  myObj = [
    {
      title: 'WEB前端开发（实习）',
      location: '广州',
      Department: '研发部',
      education: '大专以上',
      duty: [
        '根据产品需求完成PC桌面端、移动端网站开发;',
        '根据产品需求运用WEB前端技术完成移动端APP开发;',
        '根据产品需求完成H5小游戏开发;',
        '根据日常开发和线上遇到的问题，进行问题分析，定制出解决方案，保证系统的稳定性和安全性;'
      ],
      requirement: [
        '大学专科及以上学历，计算机相关专业;',
        '具备WEB前端基础知识体系，熟练掌握JavaScript、CSS、Vue等前端开发技能;',
        '实习需要全职工作;',
        '责任心强，工作积极主动，能承受工作压力;',
        '能虚心接受他人意见;',
        '“工作狂”优先！！！',
        '了解或掌握移动端开发者优先;',
        '熟悉浏览器兼容问题者优先;',
        '熟练使用Git等开发工具者优先',
        '具备较强的学习能力，具备前端框架开发经验或大型项目开发者优先，有带领团队经验的优先;',
        '了解或掌握Laya（或Adobe Animate）前端游戏引擎优先'
      ]
    },
    {
      title: '插画师（实习）',
      location: '广州',
      Department: '研发部',
      education: '大专以上',
      duty: [
        '根据需求完成插画、绘本、广告等设计;',
        '根据产品需求完成产品界面设计;'
      ],
      requirement: [
        '大学专科及以上学历，设计相关专业;',
        '具备设计功底，有一定的美感;',
        '实习需要全职工作;',
        '责任心强，工作积极主动，能承受工作压力',
        '能虚心接受他人意见',
        '“工作狂”优先！！！',
        '了解或掌握Ai优先',
        '具备较强的学习能力，有带领团队经验的优先'
      ]
    },
    {
      title: '思维老师',
      location: '广州',
      Department: '研发部',
      education: '大专以上',
      duty: [
        '主要负责儿童思维课程的研发；',
        '授课：儿童思维课程；',
        '研发：儿童思维课程，从授课中总结、不断改进课程。'
      ],
      requirement: [
        '大专以上学历，相关专业优先；',
        '良好的沟通表达能力；',
        '热爱教育行业，认真负责；',
        '有相关教学经验优先；',
        '有教师资格证优先；',
        '有珠心算经验的优先。'
      ]
    },
    {
      title: '市场销售',
      location: '广州',
      Department: '研发部',
      education: '学历不限',
      duty: [
        '评估预算、成本、收益等因素，提出产品的定价和调整建议；',
        '制定营销策略，策划并执行产品的宣传促销，获得新用户；',
        '熟悉顾客的技术、质量要求，及时反馈客户要求意见；',
        '走访市场，了解市场行情。'
      ],
      requirement: [
        '学历不限，有相关经验优先；',
        '胆大心细，性格外向，主动与他人搭话；',
        '具有营销观念和市场意识，能够敏锐地感知形势变化；',
        '有较高的“情商”，善于与人打交道，同时具备一定的营销技巧；',
        '具备推销自己的能力,把自己推销出去,才能把自己的商品推销出。'
      ]
    },
    {
      title: '销售（课程顾问）',
      location: '广州',
      Department: '研发部',
      education: '学历不限',
      duty: [
        '对来访咨询者进行接待，做好全面、准确、有针对性的咨询工作，完成每月的销售指标，发展客户关系并且对其进行维护；',
        '为客户提供咨询服务并推荐公司服务项目，引导客户签约，达成课程销售，为客户提供课程咨询服务；',
        '对现有客户或者潜在客户不定期介绍学习课程的好处；',
        '熟悉课程内容以及相关背景以及发展前景。'
      ],
      requirement: [
        '学历不限，有相关经验优先；',
        '大胆热情，善于跟陌生人打交道；',
        '服从管理，维护团队和谐氛围。'
      ]
    },
    {
      title: '销售（软件）',
      location: '广州',
      Department: '研发部',
      education: '学历不限',
      duty: [
      	'熟悉各个软件之间的联系以及功能的使用；',
      	'掌握推销技巧，维护好现有客户以及潜在客户的关系。',
      	'为客户推进最优选择方案，为客户提供软件咨询的服务。'
      ],
      requirement: [
        '学历不限，有相关经验优先；',
        '积极向上，虚心学历；',
        '服从团队管理。'
      ]
    }
  ]
  to (path: string) {
    Lib.router.push(path)
  }
  back () {
    Lib.router.go(-1)
  }
  download (path: string) {
    window.open(path)
  }
  created () {
    this.loaded = true
  }
  mounted () {
    Lib.dispatchEvent(document, 'custom-render-trigger')
    // side-nav-item1、side-nav-item-active在sideNav.vue组件中声明
    let sideNav = document.querySelector('.side-nav-item2')
    let sideNavRoute = document.querySelector('#side-nav')// 选中AppHead.vue中'联系我们'
    if (sideNavRoute) {
      sideNavRoute.classList.remove('on')
      sideNavRoute.classList.add('on')
    }
    if (sideNav) {
      sideNav.classList.remove('side-nav-item-active')
      sideNav.classList.add('side-nav-item-active')
    }
  }
}
