/**
 * Server API，数据请求交互层
 * @author JQ<jianqingchina@qq.com>
 * 2018/02/08 - 2018/09/03
 */

import Urls from './urls'
import Core from './core'
import Common from './modules/common'
import User from './modules/user'

export default class SApi {
  static Common = Common
  static User = User
  /**
   * 获取题目列表
   * @param  {object} data 登录信息除外，请求需要用的参数
   * @return {Promise}
   */
  static questions (data: SApiIF.QuestionsParam, opts?: SApiIF.OptsParam): Promise<SApiIF.Questions> {
    let generateOpts = Core.assign({
      URL: Urls.QUESTIONS,
      data,
      keyWord: '获取题目列表'
    }, opts)
    return Core.get(generateOpts)
  }
  /**
   * 获取题目数据（含需要展示哪个题目等JSON数据）
   * @param  {object} data 登录信息除外，请求需要用的参数
   * @return {Promise}
   */
  static questionsData (data: SApiIF.QuestionsDataParam, opts?: SApiIF.OptsParam): Promise<SApiIF.QuestionsData> {
    let generateOpts = Core.assign({
      URL: Urls.QUESTIONS_DATA,
      data,
      keyWord: '获取题目数据'
    }, opts)
    return Core.get(generateOpts)
  }
  /**
   * 访问统计
   * @param  {object} data 登录信息除外，请求需要用的参数
   * @return {Promise}
   */
  static recordAccess (data: SApiIF.RecordAccessParam, opts?: SApiIF.OptsParam): Promise<SApiIF.Question[]> {
    let generateOpts = Core.assign({
      URL: Urls.RECORD_ACCESS,
      data,
      keyWord: '访问统计'
    }, opts)
    return Core.get(generateOpts)
  }
  static feedBack (data: SApiIF.FeedBackParam, opts?: SApiIF.OptsParam): Promise<SApiIF.Status> {
    let generateOpts = Core.assign({
      URL: Urls.ADD_ABACUS_FEEDBACK,
      data,
      keyWord: '反馈，建议'
    }, opts)
    return Core.post(generateOpts)
  }
}
// SApi = analog.assign(SApi)

// export default SApi
